<template>
  <div class="groupBuyingGoods">
    <div class="basicInfo">
      <div class="title">图片信息</div>
      <div class="goodss">
        <ImgBox :imgList='form.loopImgArr' :disabled='disabled' @dataFormImgBox='receive'></ImgBox>
      </div>
      <div class="title">基础信息</div>
      <div class="basicBox mt30 ">
        <div class="leftContent">
          <div class="flexbox flex-align-center mt30">
            <td class="label">开通业务</td>
            <td v-for="(item,i) in supportBusinessText" :key="i" class="ml20">
              {{item}}
            </td>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">店铺名称</span>
            <span class="ml20">{{form.basicDetailVo.shopName}}</span>
          </div>

          <div class="flexbox flex-align-center mt30">
            <span class="label">商户账号</span>
            <span class="ml20">{{form.basicDetailVo.shopAccount}}</span>
          </div>

          <div class="flexbox flex-align-center mt30">
            <span class="label">店铺区域</span>
            <span class="ml20">{{form.basicDetailVo.province +form.basicDetailVo.city+form.basicDetailVo.district}}</span>
          </div>

          <div class="flexbox flex-align-center mt30">
            <span class="label">业绩归属</span>
            <span class="ml20">{{form.basicDetailVo.owner}}</span>
          </div>

          <div class="flexbox flex-align-center mt30">
            <span class="label">所属分公司</span>
            <span class="ml20">{{form.basicDetailVo.company}}</span>
          </div>

          <div class="flexbox flex-align-center mt30">
            <span class="label">补贴配送费</span>
            <el-input v-model="form.basicDetailVo.systemSubsidy" style="width: 300px;margin: 0px 10px;" placeholder="输入补贴配送费"
              :disabled="disabled" :maxlength="4" clear></el-input>
          </div>
        </div>
        <div class="rightContent">
          <div class="flexbox flex-align-center mt30">
            <span class="label">主营类目</span>
            <el-select v-model="form.basicDetailVo.service" filterable placeholder="请选择" style="width: 300px;margin: 0px 10px;"
              :popper-append-to-body="false">
              <el-option v-for="item in branchList" :key="item.id" :label="item.description" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="flexbox flex-align-center mt30 ">
            <span class="label">申请日期</span>
            <span class="ml20">{{form.basicDetailVo.createTime | formatDate}}</span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">店铺地址</span>
            <span class="ml20">{{form.basicDetailVo.address}}</span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">所属员工</span>
            <span class="ml20">{{form.basicDetailVo.staffName}}</span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">关联顺丰门店</span>
            <el-input v-model="form.basicDetailVo.sfShopNo" style="width: 300px;margin: 0px 10px;" placeholder="输入关联顺丰门店"
              :disabled="disabled" :maxlength="30" clear></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">会员群推荐语</span>
            <el-input v-model="form.basicDetailVo.memberGroupRecommend" style="width: 300px;margin: 0px 10px;" placeholder="加入粉丝福利群，更多福利优惠"
              :disabled="disabled" :maxlength="30" clear></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="specificationInfo" v-if="this.supportBusinessnum.indexOf(8)>-1">
      <div class="title">
        <span>返利信息</span>
        <span class="ml20" style="font-weight:normal">{{form.rebateDetailVo.state | formatState}}</span>
      </div>
      <div class="basicBox">
        <div class="leftContent">
          <div class="flexbox flex-align-center mt30">
            <span class="label">用户买单折扣</span>
            <el-input v-model="form.rebateDetailVo.customerDiscount" style="width: 300px;margin: 0px 10px;" :disabled="disabled"
              :maxlength="3" clear @input="rebateComputed"></el-input>%
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">返佣比例</span>
            <el-input v-model="form.rebateDetailVo.rebate" style="width: 300px;margin: 0px 10px;" disabled :maxlength="5" clear></el-input>%
          </div>
        </div>
        <div class="rightContent">
          <div class="flexbox flex-align-center mt30">
            <span class="label">商户结算比例</span>
            <el-input v-model="form.rebateDetailVo.shopDiscount" style="width: 300px;margin: 0px 10px;" :disabled="disabled" :maxlength="3"
              clear @input="rebateComputed"></el-input>%
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">不参与优惠部分抽佣比例</span>
            <el-input v-model="form.rebateDetailVo.noCommission" style="width: 300px;margin: 0px 10px;" :disabled="disabled" :maxlength="3"
              clear></el-input>%
          </div>
        </div>
      </div>
    </div>
    <div class="specificationInfo" v-if="this.supportBusinessnum.indexOf(4)>-1">
      <div class="title">
        <span>堂食业务</span>
        <span class="ml20" style="font-weight:normal">{{form.tableDetailVo.state | formatState}}</span>
      </div>
      <div class="mt20 ml50">
        <span>已绑定桌码({{form.tableDetailVo.tableDetailVoLength}})</span>
      </div>
      <div class=" flexbox flex-wrap qrcodeBox">
        <div v-for="(item, index) in form.tableDetailVo.tableBind" :key="index" class="card-main flexbox flex-tb pointer">
          <div class="flexbox">
            <div class="table-no">{{ item.tableNumber }}</div>
          </div>
          <div v-if="item.qrCodeEncryption" class="center">
            <img class="qr-code-img" :src="getQrcodeSrc(item.qrCodeEncryption,58)" />
            <div class="qr-code-text">{{item.qrCode}}</div>
          </div>
        </div>
      </div>
      <div class="flexbox flex-align-center flex-justify-between">
        <div class="flexbox flex-align-center mt30">
          <span class="label">餐位费</span>
          <el-input max="30" oninput="value=value.replace(/[^\d.]/g,'')" v-model="form.tableDetailVo.mealFee"
            style="width: 300px;margin: 0px 10px;" placeholder="0" />元/人
        </div>
        <div class="flexbox flex-align-center mt30">
          <span class="label">支付方式</span>
          <el-checkbox-group v-model="payCheckList" class="ml20" :min='1' :max='2'>
            <el-checkbox label="1">线上支付</el-checkbox>
            <el-checkbox label="2">线下支付</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flexbox flex-align-center mt30"></div>
      </div>
    </div>

    <div class="specificationInfo" v-if="this.supportBusinessnum.indexOf(1)>-1">
      <div class="title">
        <span>外卖业务</span>
        <span class="ml20" style="font-weight:normal">{{form.distributionDetailVo.state | formatState}}</span>
      </div>
      <div class="flexbox flex-align-center mt30">
        <span class="label">配送方式</span>
        <el-radio-group v-model="form.distributionDetailVo.distributionType" class="ml20" @change="deliveryChange">
          <el-radio :label="1">第三方配送</el-radio>
          <el-radio :label="2">商户自配送</el-radio>
        </el-radio-group>
      </div>
      <div class="mt50">配送设置</div>
      <div class="goods">
        <ImgBox :imgList='takeOutImgArr' @dataFormImgBox='takeOutReceive'></ImgBox>
      </div>
      <div class="basicBox mt50">
        <div class="leftContent">
          <div class="flexbox flex-align-center mt30">
            <span class="label">法人姓名</span>
            <el-input v-model="form.distributionDetailVo.idCardName" style="width: 300px;margin: 0px 10px;" :disabled="disabled"
              :maxlength="30" clear></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">营业执照号码</span>
            <el-input v-model="form.distributionDetailVo.businessPathNumber" style="width: 300px;margin: 0px 10px;" :disabled="disabled"
              :maxlength="30" clear></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">配送价格</span>
            <el-input v-model="form.distributionDetailVo.distributionOwnPrice" style="width: 300px;margin: 0px 10px;" :disabled="disabled"
              :maxlength="30" clear v-if="form.distributionDetailVo.distributionType===2"></el-input>
            <span v-else style="margin-left:10px">-</span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">起送价格</span>
            <el-input v-model="form.distributionDetailVo.startPrice" style="width: 300px;margin: 0px 10px;" :disabled="disabled"
              :maxlength="30" clear></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">是否允许骑手查看商品信息</span>
            <el-switch v-model="riderCheck" class="ml10"></el-switch>
          </div>
        </div>
        <div class="rightContent">
          <div class="flexbox flex-align-center mt30">
            <span class="label">法人身份证号码</span>
            <el-input v-model="form.distributionDetailVo.legalPersonIdCardNumber" style="width: 300px;margin: 0px 10px;"
              :disabled="disabled" :maxlength="30" clear></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">用户承担配送费比例</span>
            <el-input v-model="form.distributionDetailVo.distributionThirdUserRate" style="width: 300px;margin: 0px 10px;"
              :disabled="form.distributionDetailVo.distributionType===2?true:false" :maxlength="5" clear></el-input>%
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">配送范围</span>
            <span class="ml20">{{deliveryCalc|| 0}}公里</span>
            <span class="ml20" @click="showDelect">选择 ></span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">预估商家出餐时间</span>
            <el-input v-model="form.distributionDetailVo.mealTime" style="width: 300px;margin: 0px 10px;" :disabled="disabled"
              :maxlength="3" clear></el-input>分钟（不得低于5分钟）
          </div>
        </div>
      </div>
    </div>
    <div class="specificationInfo" v-if="this.supportBusinessnum.indexOf(2)>-1">
      <div class="title">
        <span>团购业务</span>
        <!-- 审核中 审核通过 审核失败 -->
        <span class="ml20" style="font-weight:normal">{{form.groupDetailVo &&form.groupDetailVo.state | formatState}}</span>
      </div>
    </div>
    <div class="tc mt20">
      <el-button type="primary" @click="saveSettlementRule" class="bottombtn">保存</el-button>
      <el-button class="bottombtn" @click="goBack">关闭</el-button>
    </div>
    <!-- 保存并发布提示弹窗 -->
    <Dialog ref="delectDialog" dialogWidth="800px" @close="destoryMap">
      <div class="item-title mt40 flexbox flex-align-center">配送范围</div>
      <div class="delivery-item-box">
        <div class="delivery-item flexbox flex-align-center">
          <div>
            <span>配送半径</span>
            <span>{{deliveryCalc}}</span>
            <span class="unit">公里</span>
            <span class="recovery pointer ml20" @click="
                  form.distributionDetailVo.distributionOwnRadius = 5;
                  reDrawCricle();
                ">恢复默认</span>
          </div>
        </div>
        <div id="AddShippingSettingMapPc" style="width: 700px; height: 400px; margin-top: 20px"></div>
      </div>
      <div class="dialogBtn flexbox flex-align-center flex-justify-center">
                <el-button class="btn" type="primary" @click="confirmDelect">确认</el-button>
              </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from '@/components/common/dialog.vue';
import AMapUtils from '@base/utils/map/AMapUtils';
import { getQrcodeSrc } from '@/global/globalMethods';
// import FileUploader from '@base/utils/file/FileUploader';
import {
  //  $sg_getMchGroupCatSelect, $sp_createGroupBuying,
  $sp_shopInfo,
  $sp_updateShop,
  $sp_getShopCate,
} from '@/api/business';

import {
  $agent_selectAgent,
} from '@/api/agentAll';
import ImgBox from './components/ImgBox.vue';


export default {
  data() {
    return {
      // singleMaxs: '', // 判断限购
      // singleMaxss: '', // 判断限购
      getQrcodeSrc,
      returns: '',
      branch: '',
      row: '',
      payCheckList: [],
      sendCheckList: [],
      buyTime: [],
      useTime: [],
      supportBusinessText: [],
      supportBusinessnum: [],
      takeOutImgArr: [
        {
          url: '',
          sort: 1,
          title: '法人身份证',
        },
        {
          url: '',
          sort: 2,
          title: '营业执照',
        },
        {
          url: '',
          sort: 3,
          title: '食品安全许可证',
        },
      ],
      branchList: [],
      flag: false,
      qrcodeNum: '1',
      disabled: false,
      delectDialog: false,
      riderCheck: false,
      // row: [], // 点击过来的数据
      form: {
        loopImgArr: [{ // 图片数组
          url: '',
          sort: 1,
          title: '店铺头像',
        },
        {
          url: '',
          sort: 2,
          title: '会员群二维码',
        },
        ],
        basicDetailVo: { // 基本信息列表
          address: '', // 店铺所在详细地址
          city: '', // 店铺所在城市
          company: '', // 所属分公司
          createTime: '', // 申请时间
          district: '', // 店铺所在区/县
          mapLat: '', // 纵坐标
          mapLon: '', // 横坐标
          memberGroupRecommend: '', // 会员群推荐语
          owner: '', // 业绩归属
          province: '', // 店铺所在省
          service: '', // 主营类目
          sfShopNo: '', // 关联顺丰门店
          shopAccount: '', // 商户账号
          shopName: '', // 商户名
          staffName: '', // 所属员工
          supportBusiness: '', // 支持的业务
          systemSubsidy: '', // 补贴配送费
        },
        rebateDetailVo: {
          customerDiscount: '', // 用户买单折扣
          noCommission: '', // 不参与优惠抽佣比例
          reason: '', // 被驳回的理由
          rebate: '', // 返佣比例
          shopDiscount: '', // 商户结算比例
          state: '', // 审核状态 0.审核中 1.审核通过 2审核失败 3下架
        },
        tableDetailVo: {
          goodsNumber: '', // 商品数量
          isOfflinePayment: [0], // 支付方式：1线上 2线下 3线上线下都支持
          mealFee: '', // 餐位费
          reason: '', // 被驳回的理由
          state: '', // 审核状态 0.审核中 1.审核通过 2审核失败
          tableDetailVoLength: '',
        },
        distributionDetailVo: {
          businessPath: '', // 营业执照
          businessPathNumber: '', // 营业执照号码
          deliveryRadius: '', // 配送范围
          deliveryScope: '', // 配送范围（ 0.自定义 1.全市 2.全省 3.全国）
          distributionOwnPrice: '', // 配送价格
          distributionOwnRadius: '', // 自配送配送范围
          distributionThirdUserRate: '', // 用户承担配送费比例
          distributionType: '', // 配送方式 1:第三方配送,2:店铺配送
          goodsNumber: '', // 外卖商品数量
          idCardName: '', // 法人姓名
          legalPersonIdCard: '', // 法人身份证图片
          legalPersonIdCardNumber: '', // 法人身份证号码
          mealTime: '', // 预估商户出餐时间
          permitPath: '', // 食品安全许可证
          privacySettings: '', // 骑手是否可以查看商品信息
          reason: '', // 被驳回的理由
          state: '', // 审核状态 0.审核中 1.审核通过 2审核失败
        },
      },
      shopOptions: [],
      catOptions: [],
      code: '', // 发布结果成功还是失败
      timerId: '', // 定时器id
      deliveryCalc: '',
      markerPoint: [],
    };
  },
  components: {
    ImgBox,
    Dialog,
  },
  created() {
    console.log(this.$route.query, 'this.$route.query');
    // type: 类型， 1-新增，2-编辑，3-详情
    const type = this.$route.query.type;
    this.form.shopId = this.$route.query.shopId;
    if (type === '2') {
      this.flag = true;
      this.queryGroupDetail();
    } else if (type === '3') {
      this.flag = true;
      this.queryGroupDetail();
      this.disabled = true;
      this.returns = 3;
    }
  },
  filters: {
    formatState(val) {
      switch (val) {
        case 0:
          return '审核中';
        case 1:
          return '审核通过';
        case 2:
          return '审核失败';
        case 3:
          return '下架';
        default: return '';
      }
    },
  },
  methods: {
    // 查询详情接口并渲染
    queryGroupDetail() {
      const shopId = this.form.shopId;
      $sp_shopInfo(shopId).then((res) => {
        this.form.loopImgArr[0].url = res.pictureInfo.logoPath;
        this.form.loopImgArr[1].url = res.pictureInfo.memberGroupPic;
        // 基础信息
        if (res.basicDetailVo) {
          // eslint-disable-next-line no-param-reassign
          res.basicDetailVo.service = Number(res.basicDetailVo.service) || '';
          this.form.basicDetailVo = res.basicDetailVo;
        }
        // 堂食信息
        if (res.tableDetailVo) {
          this.form.tableDetailVo = res.tableDetailVo;
          if (res.tableDetailVo.isOfflinePayment) {
            if (res.tableDetailVo.isOfflinePayment === 3) {
              this.payCheckList = ['1', '2'];
            } else {
              this.payCheckList.push(`${res.tableDetailVo.isOfflinePayment}`);
            }
          } else {
            this.payCheckList = [];
          }
          this.form.tableDetailVo.tableDetailVoLength = this.form.tableDetailVo.tableBind.length || 0;
        }
        // 返利信息
        if (res.rebateDetailVo) {
          this.form.rebateDetailVo = res.rebateDetailVo;
        }
        // 外卖信息
        if (res.distributionDetailVo) {
          this.form.distributionDetailVo = res.distributionDetailVo;
          this.riderCheck = Boolean(res.distributionDetailVo.privacySettings);
          this.takeOutImgArr[0].url = res.distributionDetailVo.legalPersonIdCard;
          this.takeOutImgArr[1].url = res.distributionDetailVo.businessPath;
          this.takeOutImgArr[2].url = res.distributionDetailVo.permitPath;
          this.deliveryChange();
        }
        // 图片列表
        const supportBusinessList = new this.$BinaryConfig('supportBusiness').value;
        this.form.basicDetailVo.supportBusiness.forEach((item) => {
          this.supportBusinessText.push(supportBusinessList.find((i) => i.value === item).label);
          this.supportBusinessnum.push(supportBusinessList.find((i) => i.value === item).value);
        });
        if (res.pictureInfo.trimPhotograph) {
          res.pictureInfo.trimPhotograph.forEach((item, index) => {
            this.form.loopImgArr.push({
              url: item,
              sort: index + 3,
              title: `店铺背景图${index + 1}`,
            });
          });
        } else {
          ['', '', ''].forEach((item, index) => {
            this.form.loopImgArr.push({
              url: item,
              sort: index + 3,
              title: `店铺背景图${index + 1}`,
            });
          });
        }
        this.markerPoint = [res.basicDetailVo.mapLon || '', res.basicDetailVo.mapLat || ''];
      });
      $sp_getShopCate().then((res) => {
        // console.log(res);
        this.branchList = res;
      });
    },
    // 初始化地图
    initMapContainer() {
      if (this.mapUtils) return;
      this.mapUtils = new AMapUtils('AddShippingSettingMapPc');
      // console.log(this.mapUtils, 'this.mapUtils');
      this.mapUtils.cityMap().then(() => {
        this.mapUtils.addMaker(
          this.markerPoint,
          '/img/shop/shop_marker.png',
          '',
          -12.5,
          -29,
        );
        this.reDrawCricle();
      });
    },
    //
    reDrawCricle() {
      if (!this.mapUtils.map) return;
      if (this.cricleMap) {
        this.mapUtils.map.remove([this.cricleMap]);
        this.cricleMap = undefined;
      }
      if (!this.form.distributionDetailVo.distributionOwnRadius) return;
      this.cricleMap = this.mapUtils.drawCircle(
        this.markerPoint[0],
        this.markerPoint[1],
        this.form.distributionDetailVo.distributionOwnRadius * 1000,
      );
      this.mapUtils.setFitView();
    },
    deliveryChange() {
      if (this.form.distributionDetailVo.distributionType === 1) {
        this.deliveryCalc = this.form.distributionDetailVo.deliveryRadius || 0;
      } else {
        this.deliveryCalc = this.form.distributionDetailVo.distributionOwnRadius;
      }
    },
    // 保存提交
    saveSettlementRule() {
      if (!this.checkSpecificationsList()) return;
      const form = this.form;
      const trimPhotograph = [];
      for (let i = 2; i <= this.form.loopImgArr.length; i += 1) {
        if (this.form.loopImgArr[i] && this.form.loopImgArr[i].url) {
          trimPhotograph.push(this.form.loopImgArr[i].url);
        }
      }
      let isOfflinePayment = '';
      if (this.payCheckList.length) {
        if (this.payCheckList.length === 2) {
          isOfflinePayment = 3;
        } else {
          isOfflinePayment = this.payCheckList.toString();
        }
      }
      const params = {
        businessPath: this.takeOutImgArr[1].url,
        permitPath: this.takeOutImgArr[2].url,
        businessPathNumber: form.distributionDetailVo.businessPathNumber,
        deliveryRadius: form.distributionDetailVo.deliveryRadius,
        distributionOwnPrice: form.distributionDetailVo.distributionOwnPrice,
        distributionOwnRadius: form.distributionDetailVo.distributionOwnRadius,
        distributionThirdUserRate: form.distributionDetailVo.distributionThirdUserRate,
        distributionType: form.distributionDetailVo.distributionType,
        idCardName: form.distributionDetailVo.idCardName,
        legalPersonIdCard: this.takeOutImgArr[0].url,
        legalPersonIdCardNumber: form.distributionDetailVo.legalPersonIdCardNumber,
        mealTime: form.distributionDetailVo.mealTime,
        privacySettings: form.distributionDetailVo.privacySettings,
        startPrice: form.distributionDetailVo.startPrice,
        mealFee: form.tableDetailVo.mealFee,
        memberGroupRecommend: form.basicDetailVo.memberGroupRecommend,
        supportBusiness: form.basicDetailVo.supportBusiness.toString(),
        systemSubsidy: form.basicDetailVo.systemSubsidy,
        service: form.basicDetailVo.service,
        sfShopNo: form.basicDetailVo.sfShopNo,
        logoPath: form.loopImgArr[0].url,
        memberGroupPic: form.loopImgArr[1].url,
        customerDiscount: form.rebateDetailVo.customerDiscount,
        noCommission: form.rebateDetailVo.noCommission,
        shopDiscount: form.rebateDetailVo.shopDiscount,
        trimPhotograph,
        shopId: this.form.shopId,
        isOfflinePayment,

      };
      // console.log(params);
      $sp_updateShop(params).then(() => {
        this.$router.go(-1);
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    receive(value) {
      this.form.loopImgArr = value;
    },
    takeOutReceive(value) {
      this.form.takeOutImgArr = value;
    },
    // 计算返佣比例
    rebateComputed() {
      this.form.rebateDetailVo.rebate = this.form.rebateDetailVo.customerDiscount * 1 - this.form.rebateDetailVo.shopDiscount * 1;
    },
    // 配送范围确认弹窗
    confirmDelect() {
      this.$refs.delectDialog.showDialog = false;
      if (this.mapUtils) {
        this.mapUtils.destroy();
      }
    },
    destoryMap() {
      this.$refs.delectDialog.showDialog = false;
      if (this.mapUtils) {
        this.mapUtils.destroy();
      }
    },
    showDelect() {
      this.$refs.delectDialog.showDialog = true;
      this.initMapContainer();
    },
    // 获取归属商户下拉列表
    getShopInfoSelect(query) {
      if (!query) return;
      const params = {
        agentName: query,
      };
      $agent_selectAgent(params).then((res) => {
        this.shopOptions = res;
      });
    },

    // 表单校验
    checkSpecificationsList() {
      const reg = /^([0-9]{1,2}|100)$/;
      if (this.supportBusinessnum.includes(8)) {
        if (!reg.test(this.form.rebateDetailVo.customerDiscount) && this.form.rebateDetailVo.customerDiscount) {
          this.$message.error('用户买单折扣格式不正确');
          return false;
        }
        if (!reg.test(this.form.rebateDetailVo.shopDiscount) && this.form.rebateDetailVo.shopDiscount) {
          this.$message.error('商户结算比例格式不正确');
          return false;
        }
        if (!reg.test(this.form.rebateDetailVo.noCommission) && this.form.rebateDetailVo.noCommission) {
          this.$message.error('不参与优惠部分抽佣比例格式不正确');
          return false;
        }

        const count = this.form.rebateDetailVo.customerDiscount * 1;
        if (count > 95 || count < 1) {
          this.$message.error('用户买单折扣应填写1至95折');
          return false;
        }
        if (count <= (this.form.rebateDetailVo.shopDiscount * 1)) {
          this.$message.error('用户买单折扣需大于结算比例');
          return false;
        }
      }
      if (this.supportBusinessnum.includes(1)) {
        if (!reg.test(this.form.distributionDetailVo.distributionThirdUserRate)
          && this.form.distributionDetailVo.distributionThirdUserRate) {
          this.$message.error('用户承担配送费比例格式不正确');
          return false;
        }
      }
      if (this.form.distributionDetailVo.mealTime < 5 && this.supportBusinessnum.indexOf(1) > -1) {
        this.$message.error('预估商家出餐时间不能低于5分钟');
        return false;
      }
      if ((this.form.basicDetailVo.systemSubsidy * 1 < 0 || this.form.basicDetailVo.systemSubsidy * 1 > 5)
        && this.form.basicDetailVo.systemSubsidy) {
        this.$message.error('补贴配送费应在0-5之间');
        return false;
      }
      return true;
    },
    // eslint-disable-next-line consistent-return
    async checkForm() {
      await this.$validator({
        ...this.form,
      }, [
        'shopName/require/商品标题',
        'remark/require/商品描述',
      ]);
      return true;
    },

    // 检验封面函数
    checkImgList() {
      // eslint-disable-next-line no-plusplus
      const result = this.form.loopImgArr.some((v) => v.url);
      if (result === false) {
        this.$message.error('请至少选择一张图片');
        return false;
      } else {
        return true;
      }
    },
    // 判断用户首页图片
    checkGoodsImg() {
      if (this.form.loopImgArr[0].url) {
        this.form.goodsPath = this.form.loopImgArr[0].url;
      } else {
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.form.loopImgArr.length; i++) {
          if (this.form.loopImgArr[i].url) {
            this.form.goodsPath = this.form.loopImgArr[i].url;
            break;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.returns {
  width: 100%;
  height: 50px;
  .btn {
    width: 290px;
    height: 50px;
    border-radius: 60px;
  }
}
.goods {
  font-size: 14px;
  width: 100%;
  .tips {
    margin-left: 10px;
    margin-top: 10px;
    margin-left: 70px;
  }
}
.groupBuyingGoods {
  .mr {
    margin-right: 20px;
  }
  width: 100%;
  .basicInfo {
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    color: #666;
    background-color: #fff;
    overflow: auto;
    .title {
      font-size: 15px;
      font-weight: bold;
    }

    .goodss {
      font-size: 14px;
      width: 100%;
      .tips {
        margin-left: 10px;
        margin-top: 10px;
        margin-left: 70px;
      }
    }
    .basicBox {
      display: flex;
      justify-content: space-between;
      margin-left: 50px;
      margin-right: 150px;
      .tips {
        width: 350px;
        font-size: 12px;
        color: #f63d29;
        margin-top: 10px;
        margin-left: 69px;
      }
      .label {
        width: 100px;
        text-align: right;
      }
      .el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }
  .basicBox {
    display: flex;
    justify-content: space-between;
    // margin-left: 50px;
    margin-right: 150px;
    .tips {
      width: 350px;
      font-size: 12px;
      color: #f63d29;
      margin-top: 10px;
      margin-left: 69px;
    }
    .label {
      width: 180px;
      text-align: right;
    }
    .el-checkbox__inner {
      border-radius: 50%;
    }
  }
  .specificationInfo {
    .title {
      font-size: 15px;
      font-weight: bold;
    }
    margin-top: 20px;
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    color: #666;
    background-color: #fff;
    overflow: auto;
    .content {
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
  .goodsDetail {
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    .title span:first-child {
      font-size: 15px;
      font-weight: bold;
    }
    .title span:last-child {
      font-size: 12px;
      color: #ef3f46;
      margin-left: 10px;
    }
    .btnBox {
      width: 100%;
      margin-top: 20px;
      .btn {
        width: 290px;
        height: 60px;
        border-radius: 60px;
      }
    }
  }
  .dialogBtn {
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 200px;
      height: 50px;
      border-radius: 50px;
    }
  }
  .qrcodeBox {
    .card-main {
      width: 208px;
      height: 208px;
      background: #ffffff;
      border: 1px solid #ececec;
      border-radius: 8px;
      margin-left: 50px;
      margin-top: 20px;
      padding: 17px 20px;
      box-sizing: border-box;
      position: relative;
      .table-no {
        font-size: 23px;
        font-weight: 400;
        line-height: 32px;
        color: #666666;
      }
      .qr-code-img {
        width: 78px;
        height: 77px;
        margin-top: 27px;
      }
      .qr-code-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #999999;
        margin-top: 16px;
      }
    }
  }
  .item-title {
    .delivery-item {
      margin-top: 26px;
      & > div {
        margin-right: 60px;
        & > span {
          margin-right: 12px;
        }
        .tips {
          font-size: 12px;
          color: #999;
          margin-left: 4px;
        }
        .recovery {
          font-size: 14px;
          color: #ef3f46;
          margin-left: 28px;
        }
      }
    }
  }
  .ml50 {
    margin-left: 50px;
  }
  .mt50 {
    margin-top: 50px;
  }
  .tc {
    text-align: center;
  }
  .bottombtn {
    width: 200px;
    height: 50px;
    border-radius: 50px;
  }
}
</style>
