import api from './api';
// 代理商列表
export const $agent_selectAgent = (param) => api.get('/api/agent/biz/agent/selectAgentFromPlat', param, {
  headers: {
    tags: 'dev',
  },
});
// /api/agent/biz/merchant/selectTShopInfo
export const $agent_selectTShopInfo = () => api.get('/api/agent/biz/merchant/selectTShopInfo');
// 新增代理商 /api/agent/biz/agent/insertAgent
export const $agent_insertAgent = (param) => api.post('/api/agent/biz/agent/insertAgent', param, {
  headers: {
    tags: 'dev',
  },
});
// 编辑代理商 /api/agent/biz/agent/updateByAgentSid
export const $agent_updateByAgentSid = (param) => api.post('/api/agent/biz/agent/updateByAgentSid', param, {
  headers: {
    tags: 'dev',
  },
});
// 商户列表 /api/agent/biz/merchant/shopPage
export const $agent_shopPage = (param) => api.postNoMessage('/api/agent/biz/merchant/shopPage', param, {
  headers: {
    tags: 'dev',
  },
});
// 添加下级代理商 /api/agent/biz/agent/addAgentOne http://192.168.2.211:7002/
export const $agent_addAgentOne = (param) => api.post('/api/agent/biz/agent/addAgentV2One', param);
// 推荐代理商  /agent/dim/agentV2sByKey
export const $agent_agentV2sByKey = (param) => api.get('/api/agent/biz/agent/dim/agentV2sByKey', param);
// 归属员工查询 /agent/dim/staffsByKey
export const $agent_staffsByKey = (param) => api.get('/api/agent/biz/agent/dim/staffsByKey', param);
// 代理商查询资料 /agent/detail
export const $agent_agentUserDetail = (param) => api.get(`/api/agent/biz/agent/detail/${param}`);
// 编辑下级代理商 /api/agent/biz/agent/addAgentOne http://192.168.2.211:7002/
export const $agent_updateAgentOne = (param) => api.post('/api/agent/biz/agent/updateAgentV2One', param);
// 查看上级代理商 /api/agent/biz/agent/selectPreAgentPlat
export const $agent_selectPreAgentPlat = (param) => api.get('/api/agent/biz/agent/selectPreAgentPlat', param);
// 投放状态修改 /j/WxLocaladAd/updateShowType
// 查询归属分公司列表
// export const $sp_spreadList = (params) => api.postNoMessage('/j/platform/t-spread-info/query/list', params);
// 区域树状图;/api/agent/biz/agent/area/tree
export const $sp_areaTree = () => api.get('/api/agent/biz/agent/area/tree');
