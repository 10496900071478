import api from './api';

// 获取平台下菜品的列表
export const $sg_getShopGoodsList = (params) => api.get('/mall/v1/GoodsManage/getShopGoodsList', params);
// 添加/修改商品
export const $sp_saveGoods = (params) => api.post('/mall/v1/GoodsManage/saveGoods', params);
// 切换商品状态
export const $sp_shopSwitchGoodsState = (params) => api.post('/mall/v1/GoodsManage/shopSwitchGoodsState', params);
// 批量导入商品数据
export const $sp_migrateGoods = (params) => api.post('/mall/v1/GoodsManage/migrateGoods', params);
// 获取商品分类
export const $sg_getGoodsSorts = (params) => api.get('/mall/v1/GoodsManage/getGoodsSort', params);
// 检测啦啦外卖 乐外卖导入情况
export const $sp_getExportStatus = (params) => api.postNoTips('/mall/v1/GoodsManage/getExportStatus', params);
// 获取商家对商品的权限配置和营销配置
export const $sgw_getShopAuthorityV2 = () => api.getNoMessage('/platform/v2/SpreadConfig/getShopAuthorityV2');
// 恢复商品
export const $sg_RecycleGoods = (params) => api.getNoRejectNoTips('/mall/v1/goods/recoveryRecycleGoods', params);
// 获取商品属性和规格
export const $sg_getGoodsAttrAndSpec = (params) => api.get('/mall/v1/GoodsManage/getGoodsAttrAndSpec', params);
// 保存商品规格
export const $sp_saveGoodsSpec = (params) => api.post('/mall/v1/GoodsManage/saveGoodsSpec', params);
// 删除商品规格/属性值
export const $sp_delGoodsSpecAndAttr = (params) => api.post('/mall/v1/GoodsManage/delGoodsSpecAndAttr', params);
// 删除属性名
export const $sp_delGoodsAttr = (params) => api.post('/mall/v1/GoodsManage/delGoodsAttr', params);
// 保存商品属性
export const $sp_saveGoodsAttr = (params) => api.post('/mall/v1/GoodsManage/saveGoodsAttr', params);
// 规格多选
export const $sp_updateAttrCheckboxed = (params) => api.post('/mall/v1/GoodsManage/updateAttrCheckboxed', params);
// 获取全部规格
export const $sp_getSpreadAllAttr = (params) => api.get('/platform/v4/SpreadConfig/getSpreadAllAttr', params);
// 获取属性值
export const $sp_getAttrInfo = (params) => api.get('/platform/v4/SpreadConfig/getAttrInfo', params);
// 获取该分类下的所有商品
export const $sp_getShopGoodsList = (params) => api.get('/mall/v1/GoodsManage/getShopGoodsList', params);
// 删除二级分类接口
export const $sp_switchGoodsChildSortState = (params) => api.postNoMessage('/mall/v1/GoodsSort/switchGoodsChildSortState', params);
// 添加、修改二级分类接口
export const $sp_saveGoodsChildSort = (params) => api.post('/mall/v1/GoodsSort/saveGoodsChildSort', params);
// 店铺开启/关闭二级分类接口
export const $sg_switchOpenChildSort = (params) => api.post('/mall/v1/GoodsSort/switchOpenChildSort', params);
// 保存/修改商品分类
export const $sp_saveGoodsSortV2 = (params) => api.post('/mall/v2/Goods/saveGoodsSort', params);
// 切换商品分类状态隐藏、显示、删除
export const $sp_switchGoodsSortState = (params) => api.postNoMessage('/mall/v2/Goods/switchGoodsSortState', params);
// 检查店铺是否开启二级分类
export const $sg_checkOpenChildSort = (params) => api.get('/mall/v1/GoodsSort/checkOpenChildSort', params);
// 获取所有商品分类
export const $sg_getShopSortsV2 = (params) => api.get('/mall/v2/Goods/getShopSortsV2', params);
// 批量同步商品属性到其它商品
export const $sp_setAttrToOtherGoods = (params) => api.post('/mall/v1/GoodsManage/setAttrToOtherGoods', params);
// 批量更新商品属性
export const $sp_updateGoodsList = (params) => api.post('/mall/v3/Goods/updateGoodsList', params);
// 平台获取商品分类
export const $sg_getGoodsSort = (params) => api.get('/mall/v1/GoodsManage/getGoodsSort', params);
// 批量上传图片-匹配图片名称
export const $sp_checkUploadImg = (params) => api.post('/mall/v3/GoodsManage/checkUploadImg', params);
// 批量上传图片-匹配图片名称
export const $sp_saveGoodsPath = (params) => api.postNoMessage('/mall/v3/GoodsManage/saveGoodsPath', params);
// 获取商品详情
export const $sg_getGoodsDetails = (params) => api.get('/mall/v1/GoodsManage/getGoodsDetails', params);
// 保存商品详情
export const $sp_saveGoodsDetails = (params) => api.post('/mall/v1/GoodsManage/saveGoodsDetails', params);
// 获取对应店铺的商品列表
export const $sg_getGroupShopGoods = (params) => api.get('/group/v1/GroupBuy/getGroupShopGoods', params);
// 通过spu获取商品列表
export const $sg_getSpuList = (params) => api.get('/mall/v3/Goods/getSpuList', params);
//
export const $sg_getShopBaseInfo = () => api.get('/mall/v1/ShopManager/getShopBaseInfo');
// 平台商品(库)列表
export const $sg_listSpreadGoods = (params) => api.get('/goods/v1/SpreadGoods/listSpreadGoods', params);
// 导入平台库商品到店铺
export const $sg_exportSpreadGoods = (params) => api.post('/goods/v1/SpreadGoods/exportSpreadGoods', params);
// 获取平台商品分类列表
export const $sg_getSpreadGoodsCategory = (params) => api.get('/goods/v1/SpreadGoods/getSpreadGoodsCategory', params);
/**
 * @api {post} /general/v1/Auth/getSpreadCountByTel 店长端获取手机号注册了多少平台
 * @apiGroup Auth
 * @apiParam {string} tel 手机号.
 * @apiSuccessExample 成功示例:
 * {
 *  "code": 1000,
 *  "msg": "获取成功",
 *  "data": {
 *       1, //注册平台数量（0表示未注册到任何平台）
 *  }
 * }
 */
export const $sp_getSpreadCountByTel = (params) => api.postNoMessage('/general/v1/Auth/getSpreadCountByTel', params);
// 批量同步商品规格到其它商品
export const $sp_setSpecToOtherGoods = (params) => api.post('/mall/v1/GoodsManage/setSpecToOtherGoods', params);
// 商品售完 v2.0
export const $sgv20_goodsSoldOut = (params) => api.post('/j/goods/goodsSoldOut', params);
// 删除商品 v2.0
export const $sgv20_delGoods = (params) => api.post('/j/goods/delGoods', params);
// 商品状态修改 v2.0
export const $sgv20_updateGoodsState = (params) => api.post('/j/goods/updateGoodsState', params);
// 获取商品分类 v2.0
export const $sgv20_getGoodsSort = (params) => api.get('/j/goods/getGoodsSort', params);
// 查询商品列表 v2.0
export const $sgv20_getShopGoodsList = (params) => api.get('/j/goods/getShopGoodsList', params);
// 更改商品排序 v2.0
export const $sgv20_saveGoodsWeight = (params) => api.post('/j/goods/saveGoodsWeight', params);
// 添加/修改商品分类 v2.0
export const $sgv20_saveOneGoodsSort = (params) => api.post('/j/goods/saveOneGoodsSort', params);
// 删除分类 v2.0
export const $sgv20_delOneGoodsSort = (params) => api.post('/j/goods/delOneGoodsSort', params);
export const $sgv20_saveGoods = (params) => api.post('/j/goods/saveGoods', params);
export const $sp_goodsSave = (params) => api.post('/w/admin/goods/save', params);
// 查询商品详情接口 v2.0
export const $sgv20_getOneGoods = (params) => api.get('/j/goods/getOneGoods', params);
// 获取商品属性和规格 v2.0
export const $sgv20_getGoodsAttrAndSpec = (params) => api.get('/j/goods/getGoodsAttrAndSpec', params);

// 查询素材库图片图片
export const $sg_getImageSource = (params) => api.get('/mall/v3/Goods/getImageSource', params);
// 查询网络图片
export const $sg_findByName = (params) => api.get('/j/netImage/findByName', params);
// 获取商家支持的商品类型(外卖或者堂食) v2.0
export const $sgv20_getShopSupport = (params) => api.get('/j/goods/getShopSupport', params);
