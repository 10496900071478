<template>
  <div class="goodsList-container">
    <div class="header flexbox flex-lr">
      <div class="headerSearch flex_1 flexbox flex-lr flex-align-center">
        <el-form :inline="true" :model="form">
          <el-form-item>
            <el-input size="medium" v-model="form.goodsName" placeholder="商品名称" style="width:180px;"
              clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-select size="medium" v-model="form.state" placeholder="商品状态" style="width:180px;"
              clearable>
              <el-option v-for="type in statusOptions" v-bind="type" :key="type.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select size="medium" v-model="form.suportBusiness" placeholder="支持形式"
              style="width:180px;" clearable>
              <el-option label="堂食" :value="2"></el-option>
              <el-option label="外卖" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select size="medium" v-model="form.goodsSortId" clearable placeholder="商品分类"
              style="width:180px;" @change="changeSelectedClassify" @clear="clearSelected">
              <el-option v-for="item in sort" :key="item.goodsSortId" :label="item.sortName"
                :value="item.goodsSortId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select size="medium" v-model="form.singleAble" clearable placeholder="是否可单点" style="width:120px;">
              <el-option label="可单点" :value="1"></el-option>
              <el-option label="不可单点" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <img class="searchIcon" src="/img/searchIcon.png" alt="" @click="searchGoodsList">
      </div>
      <!-- <div class="headerAdd flexbox flex-lr flex-align-center flex-justify-center">
        <div class="update1 flexbox flex-lr flex-align-center flex-justify-center"
          @click="toUpdate">美团/饿了么导入商品</div>
        <div class="update2 flexbox flex-lr flex-align-center flex-justify-center"
          @click="addProduct">
          <img class="addIcon" src="/img/goods/add.png" alt="">
          添加商品
        </div>
      </div> -->
    </div>
    <div class="content flexbox flex-lr flex-align-stretch">
      <div class="contentLeft">
        <div class="contentLeftClassify">
          <div class="ClassifyLi flexbox flex-lr flex-align-center flex-justify-center"
            :class="{active: active === item.goodsSortId}" v-for="(item, index) in sort"
            :key="index" @click="selectedClassify(item)">
            <el-badge value="必选" class="item" type="warning" v-if="item.sortType == 2">
              <span class="ellipsis">{{item.sortName}}</span>
            </el-badge>
            <span class="ellipsis" v-else>{{item.sortName}}</span>
          </div>
        </div>
        <!-- <div class="addClassifyBtn flexbox flex-lr flex-align-center flex-justify-center"
          @click="dialogVisible = true">
          <img class="addClassifyBtnIcon pointer" src="/img/goods/addClassify.png" alt="">
          添加分类
        </div> -->
      </div>
      <div class="contentRight flex_1 beautiful-scorll-bar" id="contentRight">
        <el-table :data="goodsList" border stripe style="width: 100%;" :max-height="tableMaxHeight">
          <el-table-column prop="goodsPath" align="center" label="商品图片">
             <template slot-scope="scope">
              <img :src="scope.row.goodsPath" alt="" class="table-img">
            </template>
          </el-table-column>
          <el-table-column prop="goodsId" align="center" label="商品编号"></el-table-column>
          <el-table-column prop="goodsName" align="center" label="商品名称"></el-table-column>
          <el-table-column prop="scanPrice" align="center" width="100" label="堂食单价"></el-table-column>
          <el-table-column prop="price" align="center" width="100" label="外卖单价"></el-table-column>
          <el-table-column prop="attribute" align="center" width="100" label="规格">
            <template slot-scope="scope">
              <span>{{+scope.row.attribute === 2 || +scope.row.attribute === 4?'有':'无'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="isAttribute" align="center" width="100" label="属性">
            <template slot-scope="scope">
              <span>{{+scope.row.attribute === 3 || +scope.row.attribute === 4?'有':'无'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="suportBusiness" align="center" width="100" label="堂食">
            <template slot-scope="scope">
              <span>{{+scope.row.suportBusiness === 2 || +scope.row.suportBusiness === 3?'支持':'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="suportBusiness" align="center" width="100" label="外卖">
            <template slot-scope="scope">
              <span>{{+scope.row.suportBusiness === 1 || +scope.row.suportBusiness === 3?'支持':'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="suportBusiness" align="center" width="100" label="状态">
            <template slot-scope="scope">
              <!-- <el-select v-model="scope.row.state" size="mini" @change="editGoodsState(scope.row)">
                <el-option v-for="item in states" :key="item.value" :label="item.name"
                           :value="item.value"> </el-option>
              </el-select> -->
              <span v-if="scope.row.state===1">上架</span>
              <span v-if="scope.row.state===2">下架</span>
              <span v-if="scope.row.state===3">售完</span>
              <span v-if="scope.row.state===4">删除</span>
              <!--
              <span
                :class="`state${scope.row.suportBusiness === 3?states[scope.row.state].value:
                +scope.row.suportBusiness === 1?states[scope.row.state].value:states[scope.row.scanState].value}`">
                {{+scope.row.suportBusiness === 3?states[scope.row.state].name:
                +scope.row.suportBusiness === 1?states[scope.row.state].name:states[scope.row.scanState].name}}
              </span>
              -->
            </template>
          </el-table-column>
          <el-table-column prop="goodsWeight" align="center" width="100" label="排序">
            <template slot-scope="scope">
              <span>{{scope.row.goodsWeight==0?1:scope.row.goodsWeight}}</span>
              <!-- <el-input-number style="width: 60px" size="mini" v-model="scope.row.goodsWeight" :min="1"
                :controls="false" :precision="0" :step="1" @change.native="editShopInfo(scope.row)">
              </el-input-number> -->
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button @click.stop="openGoodsEdit(scope.row,6)" type="text" size="mini"
                title="编辑商品信息" v-if="isEditable">详情</el-button>
              <el-button @click.stop="openGoodsEdit(scope.row)" type="text" size="mini"
                title="编辑商品信息" v-else>编辑</el-button>
              <!-- <img src="/img/activity/del.png" v-if="!isEditable" alt="删除商品" class="del" @click.stop="deleteGoods(scope.row)"/> -->
<!--              <el-button @click.stop="goodsFinish(scope.row)" type="text" style="color: #13CA7E"-->
<!--                size="mini" title="设置商品售完">售完</el-button>-->
            </template>
          </el-table-column>
          <TableEmpty slot="empty" />
        </el-table>
        <el-pagination background :page-count="pageCount" :page-size="20" :current-page.sync="page"
          @current-change="getGoodsList"></el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :show-close="false" width="562px" @close="requestAgain">
      <template slot="title">
        <div class="dialogTitle flexbox flex-lr flex-align-items-start flex-justify-between">
          <div class="dialogTitleName">添加分类</div>
          <img class="dialogClose" src="/img/goods/close.png" alt="" @click="dialogVisible = false">
        </div>
      </template>
      <div class="dialogContent">
        <el-form ref="dialogForm" :inline="true" :model="dialogForm" label-width="70px">
          <el-form-item label="添加分类">
            <el-input style="width: 140px" v-model="dialogForm.name" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input-number style="width: 140px" v-model="dialogForm.index" :min="1"
              :controls="false" :precision="0" :step="1"></el-input-number>
          </el-form-item>
          <el-form-item>
            <div class="sureAddClassify" @click="addClassify">确定添加</div>
          </el-form-item>
        </el-form>
        <div class="dialogClassifyList">
          <el-table :data="classifyList" height="380" style="width: 100%;height: 100%;">
            <el-table-column prop="sortName" align="center" width="240" label="分类名称">
              <template slot-scope="scope">
                <span v-if="!scope.row.edit">{{scope.row.sortName}}</span>
                <el-input v-else size="mini" v-model="scope.row.sortName" maxlength="10"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" width="140" label="排序">
              <template slot-scope="scope">
                <span v-if="!scope.row.edit">{{scope.row.sortWeight}}</span>
                <el-input-number v-else size="mini" type="number" v-model="scope.row.sortWeight"
                  :min="1" :controls="false" :precision="0" :step="1"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="140">
              <template slot-scope="scope">
                <div class="flexbox flex-lr flex-align-center flex-justify-center">
                  <div class="editClassifyStyle mr20" @click="deleteClassify(scope.row)">删除</div>
                  <div v-if="!scope.row.edit" class="editClassifyStyle mr20"
                    @click="editClassify(scope)">编辑</div>
                  <div v-if="scope.row.edit" class="editClassifyStyle mr20"
                    @click="saveClassify(scope)">保存</div>
                </div>
              </template>
            </el-table-column>
            <TableEmpty slot="empty" />
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  $sgv20_getGoodsSort,
  $sgv20_getShopGoodsList,
  $sgv20_saveGoodsWeight,
  $sgv20_saveOneGoodsSort,
  $sgv20_delOneGoodsSort,
  $sgv20_goodsSoldOut,
  $sgv20_delGoods,
  $sgv20_updateGoodsState,
} from '@/api/goods';
import { deepClone } from '@base/utils';

export default {
  name: 'goodsList',
  data() {
    return {
      statusOptions: [
        {
          label: '销售中',
          value: 1,
        },
        {
          label: '已下架',
          value: 2,
        },
        {
          label: '已售完',
          value: 3,
        },
      ],
      form: {
        goodsName: '',
        state: '',
        goodsSortId: '',
        suportBusiness: '',
        singleAble: '',
      },
      sort: [],
      classifyList: [],
      active: '',
      goodsList: [],
      pageCount: 1,
      page: 1,
      dialogVisible: false,
      isEditable: false,
      dialogForm: { name: '', index: '' },
      states: [
        { name: '上架', value: 1 },
        { name: '下架', value: 2 },
        { name: '售完', value: 3 },
      ],
      isClear: false,
      tableMaxHeight: 300,
    };
  },
  async created() {
    await this.getGoodsSorts();
    const height = document.getElementById('contentRight').offsetHeight;
    this.tableMaxHeight = height - 120;
    if (+this.$route.query.isEditable) {
      this.isEditable = true;
      console.log(this.isEditable, 'this.isEditable');
    }
    // await this.getGoodsList();
  },
  mounted() {

  },
  methods: {
    getGoodsSorts() {
      const prams = {
        shopId: this.$route.query.id,
      };
      return $sgv20_getGoodsSort(prams).then((res) => {
        this.sort = res;
        if (res.length > 0) {
          this.active = res[0].goodsSortId;
        }
        // this.form.goodsSortId = res[0].goodsSortId;
        this.copySortList();
        this.getGoodsList();
      });
    },
    searchGoodsList() {
      this.active = this.form.goodsSortId;
      this.getGoodsList();
    },
    getGoodsList() {
      const prams = {
        goodsName: this.form.goodsName,
        state: this.form.state,
        goodsSortId: this.form.goodsSortId || this.active,
        page: this.page,
        suportBusiness: this.form.suportBusiness,
        singleAble: this.form.singleAble,
        shopId: this.$route.query.id,
      };
      return $sgv20_getShopGoodsList(prams).then((res) => {
        res.data.forEach((item) => {
          if (+item.suportBusiness === 2) {
            item.state = item.scanState;
          }
        });
        this.pageCount = res.count;
        this.goodsList = res.data;
      });
    },
    selectedClassify(data) {
      this.active = data.goodsSortId;
      this.form.goodsSortId = '';
      this.getGoodsList();
    },
    changeSelectedClassify(val) {
      // this.active = val;
      this.form.goodsSortId = val;
      // this.getGoodsList();
    },
    clearSelected() {
    },
    editShopInfo(row) {
      let goodsWeight = row.goodsWeight;
      if (!goodsWeight && +goodsWeight !== 0) {
        goodsWeight = '';
      }
      const params = {
        goodsId: row.goodsId,
        goodsWeight,
      };
      $sgv20_saveGoodsWeight(params).then(() => {
        this.getGoodsList();
      });
    },
    copySortList() {
      this.classifyList = deepClone(this.sort);
      this.classifyList.forEach((item) => {
        item.edit = false;
      });
    },
    addClassify() {
      if (!this.dialogForm.name) {
        this.$message.error('分类名称必填');
        return;
      }
      const prams = {
        sortName: this.dialogForm.name,
        sortWeight: this.dialogForm.index || '',
      };
      $sgv20_saveOneGoodsSort(prams).then((res) => {
        console.log(res);
        this.dialogForm = {
          name: '',
          index: '',
        };
        this.getGoodsSorts();
      });
    },
    deleteClassify(row) {
      $sgv20_delOneGoodsSort({ goodsSortId: row.goodsSortId }).then((res) => {
        console.log(res);
        this.getGoodsSorts();
      });
    },
    editClassify(data) {
      const a = data.row;
      a.edit = true;
      this.$set(this.classifyList, data.$index, a);
      // this.classifyList[data.$index].edit = true;
    },
    saveClassify(data) {
      const a = data.row;
      if (!a.sortName) {
        this.$message.error('分类名称必填');
        return;
      }
      a.edit = false;
      this.$set(this.classifyList, data.$index, a);
      const prams = {
        goodsSortId: a.goodsSortId,
        sortName: a.sortName,
        sortWeight: a.sortWeight || '',
      };
      $sgv20_saveOneGoodsSort(prams).then((res) => {
        console.log(res);
        this.getGoodsSorts();
      });
    },
    addProduct() {
      this.$router.push({ name: 'GoodsDetail' });
    },
    openGoodsEdit(data, type) {
      this.$router.push({
        name: 'GoodsDetail',
        query: {
          id: data.goodsId,
          shopId: this.$route.query.id,
          type,
        },
      });
    },
    goodsFinish(data) {
      $sgv20_goodsSoldOut({ goodsId: data.goodsId }).then((res) => {
        console.log(res);
        this.getGoodsList();
      });
    },
    deleteGoods(data) {
      this.$confirm('确认要删除该商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.deleteProductReally(data.goodsId);
      }).catch(() => {
      });
    },
    deleteProductReally(id) {
      $sgv20_delGoods({ goodsId: id }).then(() => {
        this.getGoodsList();
      });
    },
    toUpdate() {
      this.$router.push({ name: 'AuthThirdPlat' });
    },
    editGoodsState(data) {
      const prams = {
        goodsId: data.goodsId, // 商品id
        state: data.state, // 修改状态 1-正常，2-下架，3-售完
        suportBusiness: data.suportBusiness, // 商品支持的业务 1:外卖业务,2:扫码点餐,3:外卖+扫码
      };
      $sgv20_updateGoodsState(prams).then((res) => {
        console.log(res);
        this.getGoodsList();
      });
    },
    requestAgain() {
      this.copySortList();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/style/var.scss";
.goodsList-container {
  width: 100%;
  height: 100%;
  min-width: 1635px;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .header {
    margin-bottom: 20px;
    .headerSearch {
      min-width: 1240px;
      height: 100px;
      background-color: $--white-color;
      margin: 0 20px 0 0;
      padding: 0 20px;
      box-sizing: border-box;
      border-radius: 6px;
      .el-form-item {
        margin: 0 60px 0 0;
      }
      .el-form-item:last-child {
        margin-right: 30px;
      }
      .searchIcon {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
    .headerAdd {
      width: 390px;
      height: 100px;
      background-color: #fff;
      margin: 0;
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 6px;
      .update1 {
        width: 163px;
        border: 1px solid #FA8828;
        font-size: 16px;
        color: #FA8828;
        padding: 9px 0;
        border-radius: 6px;
        margin: 0 15px;
        box-sizing: border-box;
        cursor: pointer;
      }
      .update2 {
        width: 128px;
        border: 1px solid $--theme-color;
        font-size: 16px;
        color: $--theme-color;
        padding: 8px 0;
        border-radius: 6px;
        margin: 0 15px;
        box-sizing: border-box;
        cursor: pointer;
        .addIcon {
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }
      }
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 120px);
    max-height: 1150px;
    overflow: auto;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .contentLeft {
      width: 226px;
      overflow: auto;
      margin: 0 20px 0 0;
      padding: 20px 0 46px 0;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: $--white-color;
      .contentLeftClassify {
        width: 100%;
        height: calc(100% - 76px);
        overflow: auto;
        margin: 0 0 20px 0;
        padding: 0;
        box-sizing: border-box;
        .ClassifyLi {
          width: 100%;
          height: 56px;
          margin: 0;
          padding: 0 20px;
          box-sizing: border-box;
          cursor: pointer;
          user-select: none;
          .ellipsis{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          /deep/ .el-badge__content.is-fixed {
            right: 0;
          }
        }
        .active {
          background: url("/img/goods/selectedClassify.png") no-repeat center
            center;
          background-size: 100% 100%;
        }
      }
      .contentLeftClassify::-webkit-scrollbar {
        width: 1px;
        background: white;
      }
      .addClassifyBtn {
        width: 204px;
        height: 56px;
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(255, 12, 12, 0.16);
        background-color: $--theme-color;
        font-size: 14px;
        font-weight: 400;
        color: $--white-color;
        cursor: pointer;
        .addClassifyBtnIcon {
          width: 26px;
          height: 26px;
          margin-right: 12px;
        }
      }
    }
    .contentRight {
      min-width: 1400px;
      height: 750px;
      /*overflow: auto;*/
      margin: 0;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: $--white-color;
      .el-pagination {
        margin-top: 50px;
      }
      .del{
        width: 14px;
        height: 16px;
        vertical-align: middle;
        margin-left: 20px;
        cursor: pointer;
      }
      .state1 {
        color: #FA8828;
      }
      .state2 {
        color: $--theme-color;
      }
      .state3 {
        color: #999999;
      }
    }
  }
  /deep/ .el-dialog {
    .el-dialog__header {
      padding: 0;
    }
    .dialogTitle {
      width: 100%;
      height: 46px;
      margin: 0;
      padding: 10px 20px 10px 35px;
      background-color: #ececec;
      box-sizing: border-box;
      .dialogTitleName {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      .dialogClose {
        width: 10px;
        height: 10px;
        cursor: pointer;
      }
    }
    .dialogContent {
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      border-radius: 8px;
      .sureAddClassify {
        font-size: 14px;
        font-weight: 400;
        color: $--theme-color;
        margin-left: 15px;
      }
      .dialogClassifyList {
        width: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        border: 1px solid #ececec;
        border-radius: 8px;
        .editClassifyStyle {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }
        .delete {
          width: 18px;
          height: 20px;
        }
        .el-input {
          width: 120px;
        }
      }
      .is-scrolling-none::-webkit-scrollbar {
        width: 1px;
        background: white;
      }
    }
  }
  .table-img{
    width: 60px;
    height: 60px;
  }
}
</style>
