<template>
  <div class="goodsDetail-container">
    <div class="header">
      <div class="choiceModule flexbox flex-lr flex-align-center">
        <div class="title flexbox flex-lr flex-align-center">基础信息</div>
        <div class="required">(必填)</div>
      </div>
      <div class="headerContent flexbox flex-lr flex-align-stretch">
        <div class="headerContentLeft">
          <img :src="$imgPrefix(form.goodsPath, '/img/upDateIcon.png')" class="avatar" />
          <div class="updateImgTips">参考尺寸：750px x 450px</div>
          <div class="changeImg" @click="changeImg" v-if="!disabled">选择图片</div>
          <div
          v-if="!disabled"
            class="chooseFromWarehouse flexbox flex-lr flex-align-center flex-justify-center mt20 pointer"
            @click="showSourceDialog">
            从素材库选择<i class="sx-icon-youjiantou"></i>
          </div>
        </div>
        <div class="headerContentMiddle flex_1">
          <el-form ref="form" :model="form" label-width="80px" label-position="left" size="mini">
            <el-form-item label="商品名称">
              <el-input size="medium" v-model="form.goodsName" placeholder="商品名称" :disabled='disabled'></el-input>
              <span class="requiredIcon" v-if="!disabled">*</span>
            </el-form-item>
            <el-form-item label="副标题">
              <el-input size="medium" v-model="form.describe" placeholder="商品副标题" :disabled='disabled'></el-input>
              <span class="requiredIcon" v-if="!disabled">*</span>
            </el-form-item>
            <el-form-item label="分类">
              <div class="changeIndex flexbox flex-lr">
                <el-select v-if="!isInput" size="medium" v-model="form.goodsSortId"
                  placeholder="选择分类" :disabled='disabled'>
                  <el-option v-for="item in goodsSort" :key="item.goodsSortId"
                    :label="item.sortName" :value="item.goodsSortId">
                  </el-option>
                </el-select>
                <el-input v-if="isInput" size="medium" v-model="form.sortName" placeholder="输入分类" :disabled='disabled'>
                </el-input>
                <!-- <div v-if="!isInput" v-show="!disabled" class="changeIndexTitle flexbox flex-lr flex-align-center"
                  @click="chooseOrInput">
                  <img class="detailAddC" src="/img/goods/detailAddClassify.png" alt="">
                  添加分类
                </div> -->
                <div v-if="isInput" class="changeIndexTitle flexbox flex-lr flex-align-center"
                  @click="chooseOrInput">
                  <img class="detailAddC" src="/img/goods/return.png" alt="">
                  返回选择
                </div>
              </div>
            </el-form-item>
            <el-form-item label="商品标签">
              <el-radio-group v-model="form.favorable" class="square-radio" :disabled='disabled'>
                <el-radio v-for="(item, index) in productState" :key="index" :label="item.value">
                  {{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="库存">
              <el-radio-group v-model="form.forceStock" class="square-radio" :disabled='disabled'>
                <el-radio :label="1">不限库存</el-radio>
                <el-radio :label="2">限制库存</el-radio>
              </el-radio-group>
              <el-input class="ml20" v-show="+form.forceStock === 2" type="number"
                v-model="form.stockCount" size="medium" placeholder="请输入库存数量"
                oninput="value=value.replace(/[^\d]/g,'')">
              </el-input>
            </el-form-item>
            <el-form-item label="打包费">
              <div class="changeIndex flexbox flex-lr">
                <el-input-number class="formGoodsWeightInput" size="medium"
                :disabled='disabled'
                   v-model="form.pack" :min="0" :max="99999"
                   :controls="false" :precision="2" :step="1"></el-input-number>
                <span class="packUnit">元</span>
                <div class="minPurchase">商品份量</div>
                <el-input-number size="medium" v-model="form.weightNum" placeholder="请输入重量" :min="0" :disabled='disabled'
                   :max="99999" :controls="false" :precision="0" :step="1" v-if="form.goodsUnit.indexOf('人份') == -1"></el-input-number>
                <el-cascader size="medium" v-model="form.goodsUnit" :options="goodsUnitOptions" 
                   :show-all-levels="false" style="margin-left: 20px;" @change="selectGoodsUnit"></el-cascader>
              </div>
            </el-form-item>
            <el-form-item label="排序">
              <div class="changeIndex flexbox flex-lr">
                <el-input-number class="formGoodsWeightInput" size="medium" :disabled='disabled'
                  v-model="form.goodsWeight" placeholder="排序" :min="1" :max="99999"
                  :controls="false" :precision="0" :step="1"></el-input-number>
                <span class="packUnit" style="margin-right: 14px"></span>
                <div class="minPurchase">起购份数</div>
                <el-input-number size="medium" v-model="form.value" placeholder="排序" :min="1" :disabled='disabled'
                  :max="99999" :controls="false" :precision="0" :step="1"></el-input-number>
              </div>
            </el-form-item>
            <el-form-item label="单点不送">
              <el-switch v-model="form.singleAble" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="headerContentRight flexbox flex-lr flex-align-items-end" v-if="!disabled">
          <div class="stateBtn" :class="{selectedStateBtn: +form.state === 1}"
            @click="selectState(1)">上架</div>
          <div  class="stateBtn" :class="{selectedStateBtn: +form.state === 2}"
            @click="selectState(2)">下架</div>
          <div  class="stateBtn" :class="{selectedStateBtn: +form.state === 3}"
            @click="selectState(3)">售完</div>
        </div>
        <div class="headerContentRight flexbox flex-lr flex-align-items-end" v-else>
          <span v-if="this.form.state===1" class="stateBtn">上架</span>
            <span v-if="this.form.state===2" class="stateBtn">下架</span>
            <span v-if="this.form.state===3" class="stateBtn">售完</span>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="choiceModule flexbox flex-lr flex-align-center">
        <div class="title flexbox flex-lr flex-align-center">商品价格</div>
        <div class="required">(必填)</div>
      </div>
      <div class="middleContent flexbox flex-lr flex-align-center">
        <div class="middleContentLeft flexbox flex-lr flex-align-center">
          <div class="middleContentLeftTitle">{{this.disabled?'':'选择'}}支持类型</div>
          <div class="middleContentLeftCon" v-if="!disabled">
            <el-checkbox-group v-model="checkedModel">
              <el-checkbox v-for="(item,index) in models" :label="item.value" :key="index">
                {{item.label}}</el-checkbox>
            </el-checkbox-group>

            <!--            <el-radio v-model="form.suportBusiness" :label="1" class="square-radio mb30">外卖</el-radio>-->
            <!--            <el-radio v-model="form.suportBusiness" :label="2" class="square-radio">到店点餐</el-radio>-->
          </div>
          <div class="middleContentLeftCon" v-else>
            <span v-if="this.judgesuportBusiness===1">外卖</span>
            <span v-if="this.judgesuportBusiness===2">堂食</span>
            <span v-if="this.judgesuportBusiness===3">外卖&emsp; &emsp;堂食</span>
          </div>
        </div>
        <div class="middleContentMiddle"></div>
        <div class="middleContentRight flexbox flex-lr flex-align-center">
          <div class="middleContentLeftTitle">外卖价格</div>
          <div class="middleContentRightCon">
            <!-- <div class="priceInput flexbox flex-lr flex-align-center">
              <div class="priceInputTitle">商品划线价</div>
              <el-input size="medium" :disabled="!checkedModel.includes(1)" type="number" v-model="form.scanOriginPrice" :maxlength="6"
                placeholder="输入价格" v-if="!disabled"></el-input>
                <el-input size="medium" type="number" v-model="form.scanOriginPrice" :maxlength="6" :disabled='disabled'
                placeholder="输入价格" v-else></el-input>
              <div class="priceInputUnit">元</div>
            </div> -->
            <div class="priceInput flexbox flex-lr flex-align-center mt20">
              <div class="priceInputTitle">商品现价</div>
              <!-- <el-input size="medium" :disabled="!checkedModel.includes(1)" type="number" v-model="form.price" :maxlength="6"
                placeholder="输入价格" v-if="!disabled"></el-input> -->
                <el-input size="medium"  type="number" v-model="form.price" :maxlength="6" :disabled='disabled'
                placeholder="输入价格"></el-input>
              <div class="priceInputUnit">元</div>
            </div>
          </div>
        </div>
        <div class="middleContentMiddle"></div>
        <div class="middleContentRight flexbox flex-lr flex-align-center">
          <div class="middleContentLeftTitle">堂食价格</div>
          <div class="middleContentRightCon">
            <!--
            <div class="priceInput flexbox flex-lr flex-align-center mb20">
              <div class="priceInputTitle">商品划线价</div>
              <el-input size="medium" :disabled="!checkedModel.includes(2)" type="number" v-model="form.scanOriginPrice" :maxlength="6"
                placeholder="请输入价格"></el-input>
              <div class="priceInputUnit">元</div>
            </div>
            -->
            <div class="priceInput flexbox flex-lr flex-align-center">
              <div class="priceInputTitle">商品价格</div>
              <!-- <el-input size="medium" :disabled="!checkedModel.includes(2)" type="number" v-model="form.scanPrice" :maxlength="6"
                placeholder="输入价格" v-if="!disabled"></el-input> -->
                <el-input size="medium" :disabled='disabled' type="number" v-model="form.scanPrice" :maxlength="6"
                placeholder="输入价格"></el-input>
              <div class="priceInputUnit">元</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="choiceModule flexbox flex-lr flex-align-center">
        <div class="title flexbox flex-lr flex-align-center">规格属性</div>
        <div class="required">(选填)</div>
        <div class="attrExplain">商品价格=基础单价+规格加价+属性加价</div>
      </div>
      <div class="bottomContent">
        <div class="bottomContentTop flexbox flex-lr flex-align-items-start">
          <div class="bottomContentTopLeft">
            <div class="specsAttrTitle">商品规格</div>
            <div class="specsAttrExplainAndAddBtn flexbox flex-lr flex-align-center">
              <div class="specsAttrExplain">如：大份 小份 中份 大杯 超大杯 中杯 大 中 小</div>
              <div class="specsAttrAddBtn" @click="addSpec" v-if="!disabled">
                <i class="el-icon-plus specsAttrAddBtnIcon"></i>
                添加规格
              </div>
            </div>
            <div class="specsList">
              <div class="specs" v-for="(item, index) in specList" :key="index">
                <div class="specsInput flexbox flex-lr flex-align-center flex-justify-center">
                  <el-input size="medium" v-model="item.goodsSpecName" placeholder="" :disabled='disabled'></el-input>
                  <div class="becauseAddIcon flexbox flex-lr flex-align-center">
                    <el-input-number size="medium" v-model="item.goodsSpecPrice" placeholder=""
                      :min="0" :max="99999" :controls="false" :precision="2" :step="1" :disabled='disabled'>
                    </el-input-number>
                    <div class="addMoneyIcon">+</div>
                    <div class="addMoneyUnit">元</div>
                  </div>
                </div>
                <img class="closeAttr" src="/img/goods/closeAttr.png" alt=""
                  @click="deleteSpec(index)" v-if="!disabled">
              </div>
            </div>
          </div>
          <div class="bottomContentTopMiddle"></div>
          <div class="bottomContentTopRight">
            <div class="specsAttrTitle">商品属性</div>
            <div class="specsAttrExplainAndAddBtn flexbox flex-lr flex-align-center">
              <div class="specsAttrExplain">先添加属性类目，再添加具体属性。如辣度分为不辣、中辣、微辣</div>
              <div class="specsAttrAddBtn" @click="addAttr" v-if="!disabled">
                <i class="el-icon-plus specsAttrAddBtnIcon"></i>
                添加属性
              </div>
            </div>
            <div class="attrList">
              <div class="attr" v-for="(item, index) in attrDtoList" :key="index">
                <div class="attrDiv">
                  <div class="attrName flexbox flex-lr flex-align-center">
                    <el-input size="medium" v-model="item.goodsAttrName" placeholder="" :disabled='disabled'></el-input>
                    <div class="childAddBtn" @click="addChildAttr(index)" v-if="!disabled">
                      <i class="el-icon-plus"></i>
                      添加
                    </div>
                  </div>
                  <div class="attrChild" v-for="(i, index2) in item.goodsSpecNameList" :key="index2">
                    <el-input clearable size="medium" v-model="i.goodsSpecName"
                      @clear="clearChildAttr(item,index2)" :disabled='disabled'></el-input>
                    <el-input clearable size="medium" v-model="i.goodsSpecPrice" type="number"
                      prefix-icon="el-icon-plus" @clear="clearChildAttr(item,index2)" :disabled='disabled'></el-input>
                      <span>元</span>
                  </div>
                </div>
                <img class="closeAttr" src="/img/goods/closeAttr.png" alt=""
                  @click="deleteAttr(index)" v-if="!disabled">
              </div>
            </div>
          </div>
        </div>
        <div class="bottomContentBottom flexbox flex-lr flex-align-center flex-justify-center" v-if="!disabled">
          <!-- <div v-if="!goodsId" class="closeEdit" @click="backToList(2)">保存并继续添加</div> -->
          <div class="submitEdit" @click="submit(1)">提交保存</div>
          <div @click="goBack" class="submitEdit">返回</div>
          <!-- <div v-if="!goodsId" class="deleteEdit" @click="deleteProduct">删除</div> -->
        </div>
        <div class="bottomContentBottom flexbox flex-lr flex-align-center flex-justify-center" v-else>
          <div @click="goBack" class="submitEdit">返回</div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" :show-close="false"
      width="970px">
      <template slot="title">
        <div class="dialogTitle flexbox flex-lr flex-align-items-start flex-justify-between">
          <div class="dialogTitleName">选择素材</div>
          <img class="dialogClose" src="/img/goods/close.png" alt="" @click="dialogVisible = false">
        </div>
      </template>
      <div class="imgSource">
        <span :class="imgSource === 1 ? 'active' : ''" @click="selectImgSource(1)">图片素材库</span>
        <span :class="imgSource === 2 ? 'active' : ''" @click="selectImgSource(2)">互联网图片</span>
      </div>
      <div class="dialogSearchDiv">
        <el-input size="medium" v-model="searchName" @keyup.enter.native="dialogSearch"
          placeholder="请输入商品名称"></el-input>
        <img class="searchIcon" src="/img/searchIcon.png" alt="" @click="dialogSearch">
      </div>
      <div class="dialogChoiceModule">
        <div class="title flexbox flex-lr flex-align-center">选择商品</div>
      </div>
      <div class="dialogImgContentDiv beautiful-scorll-bar">
        <div class="dialogImgContent flexbox flex-lr flex-wrap" v-if="dialogImgList.length > 0">
          <div class="dialogImgLi" v-for="(item, index) in dialogImgList" :key="index">
            <div class="isChoseDiv pointer" :class="{dialogChoseImg:dialogChoseImg === item.id}"
              @click="chooseThisImg(item)" v-if="imgSource === 1">
              <img class="dialogImg" :src="$imgPrefix(item.url, '', 80)" :title="item.title"
                :alt="item.title">
            </div>
            <div class="isChoseDiv pointer" :class="{dialogChoseImg:dialogChoseImg === item}"
              @click="chooseThisImg(item)" v-if="imgSource === 2">
              <img class="dialogImg" :src="$imgPrefix(item, '')">
            </div>
            <div class="dialogProductName" :title="item.title">{{item.title}}</div>
            <img class="previewIcon" src="/img/goods/preview.png" alt=""
              @click.stop="showPreviewImg(item)">
          </div>
        </div>
        <EmptyData v-else />
      </div>
      <div class="dialogPage flexbox flex-lr flex-align-center flex-justify-between">
        <div v-if="imgSource === 1">
          <el-pagination v-if="pageCount > 1" background :page-count="pageCount" :page-size="20"
            :current-page.sync="page" @current-change="getImageSource"></el-pagination>
        </div>
        <div v-if="imgSource === 2">
          <el-pagination background layout="prev, next" :current-page.sync="page" @current-change="findByName"></el-pagination>
        </div>
        <div class="dialogBtnList flexbox flex-lr flex-align-center">
          <div class="dialogBtn cancel pointer" @click="dialogVisible = false,searchName = ''">取消
          </div>
          <div class="dialogBtn confirm pointer" @click="dialogChoseConfirm">确认选择</div>
        </div>
      </div>
      <div class="dialogBlank"></div>
    </el-dialog>
    <PreviewImg :imgData="imgData" :isShowPreviewImg="isShowPreviewImg"
      @hidePreviewImg="hidePreviewImg"></PreviewImg>
  </div>
</template>

<script>
import ChooseFile from '@base/utils/file/ChooseFile';
import FileUploader from '@base/utils/file/FileUploader';
import EmptyData from '@/components/common/EmptyData.vue';
import PreviewImg from '@/components/common/PreviewImg.vue';
import {
  $sgv20_saveOneGoodsSort,
  $sp_goodsSave,
  $sgv20_getOneGoods,
  $sgv20_getGoodsAttrAndSpec,
  $sgv20_getGoodsSort,
  $sgv20_getShopSupport,
  $sgv20_delGoods,
  $sg_getImageSource,
  $sg_findByName,
} from '@/api/goods';

export default {
  name: 'goodsDetail',
  components: {
    EmptyData,
    PreviewImg,
  },
  data() {
    return {
      judgesuportBusiness: '', // 判断外卖堂食
      disabled: false,
      goodsId: '',
      form: {
        goodsPath: '',
        sortName: '',
        goodsSortId: '',
        goodsName: '',
        describe: '',
        goodsWeight: 0,
        value: 1,
        favorable: 1,
        forceStock: 1,
        stockCount: 0,
        state: 1,
        // suportBusiness: 1,
        // scanOriginPrice: '',
        scanPrice: '',
        price: '',
        pack: 0,
        weightNum: 0,
        goodsUnit: '克',
        singleAble: 1,
      },
      goodsSort: [],
      productState: [
        { label: '正常', value: 1 },
        { label: '促销', value: 2 },
        { label: '招牌', value: 3 },
        { label: '特惠', value: 4 },
        { label: '新品', value: 5 },
        { label: '推荐', value: 6 },
      ],
      models: [
        { id: 1, label: '外卖', value: 1 },
        { id: 2, label: '堂食', value: 2 },
      ],
      specList: [
        // { goodsSpecName: '', goodsSpecPrice: '' },
      ],
      attrDtoList: [
        // {
        //   goodsAttrName: '',
        //   goodsSpecNameList: [''],
        // },
      ],
      isInput: false,
      checkedModel: [],
      shopSupport: [],
      dialogVisible: false,
      searchName: '',
      dialogImgList: [],
      dialogChoseImg: '',
      shopId: '',
      pageCount: 1,
      page: 1,
      imgData: {},
      isShowPreviewImg: false,
      loading: false,
      imgSource: 1, // 图片来源 1-图片素材库，2-互联网图片
      
      goodsUnitOptions: [
        {
          value: 1,
          label: '重量单位',
          children: [
            {
              value: '克',
              label: '克',
            },
            {
              value: '千克',
              label: '千克',
            },
            {
              value: '两',
              label: '两',
            },
            {
              value: '斤',
              label: '斤',
            },
            {
              value: '磅',
              label: '磅',
            },
            {
              value: '毫升',
              label: '毫升',
            },
            {
              value: '升',
              label: '升',
            },
            {
              value: '厘米',
              label: '厘米',
            },
            {
              value: '英寸',
              label: '英寸',
            },
            {
              value: '盎司',
              label: '盎司',
            },
          ],
        },
        {
          value: 2,
          label: '数量单位',
          children: [
            {
              value: '份',
              label: '份',
            },
            {
              value: '个',
              label: '个',
            },
            {
              value: '串',
              label: '串',
            },
            {
              value: '枚',
              label: '枚',
            },
            {
              value: '粒',
              label: '粒',
            },
            {
              value: '块',
              label: '块',
            },
            {
              value: '只',
              label: '只',
            },
            {
              value: '副',
              label: '副',
            },
            {
              value: '卷',
              label: '卷',
            },
            {
              value: '片',
              label: '片',
            },
            {
              value: '贯',
              label: '贯',
            },
            {
              value: '碗',
              label: '碗',
            },
            {
              value: '杯',
              label: '杯',
            },
            {
              value: '袋',
              label: '袋',
            },
            {
              value: '瓶',
              label: '瓶',
            },
            {
              value: '盒',
              label: '盒',
            },
            {
              value: '包',
              label: '包',
            },
            {
              value: '锅',
              label: '锅',
            },
            {
              value: '罐',
              label: '罐',
            },
            {
              value: '扎',
              label: '扎',
            },
            {
              value: '条',
              label: '条',
            },
          ],
        },
        {
          value: 3,
          label: '适用人数',
          children: [
            {
              value: '1人份',
              label: '1人份',
            },
            {
              value: '2人份',
              label: '2人份',
            },
            {
              value: '3人份',
              label: '3人份',
            },
            {
              value: '4人份',
              label: '4人份',
            },
            {
              value: '5人份',
              label: '5人份',
            },
            {
              value: '6人份',
              label: '6人份',
            },
            {
              value: '7人份',
              label: '7人份',
            },
            {
              value: '8人份',
              label: '8人份',
            },
            {
              value: '9人份',
              label: '9人份',
            },
            {
              value: '10人份',
              label: '10人份',
            },
          ],
        },
      ],
    };
  },
  computed: {
    suportBusiness() {
      const value = this.checkedModel[0] | this.checkedModel[1];
      return value;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    checkedModel(value, oldValue) {
      if (!this.disabled) {
        value.forEach((item, index) => {
          if ((this.shopSupport.find((i) => item === i))) {
            // if (+item === 1) {
            //   this.$message.error('您暂未开通外卖业务，请前往外卖设置开通业务。');
            // } else if (+item === 2) {
            //   this.$message.error('您暂未开通堂食业务，请前往堂食设置开通业务。');
            // }
            value.splice(index, 1);
          }
        });
      }
    },
  },
  async created() {
    await this.getShopSupport();
    if (this.$route.query.id) {
      this.goodsId = this.$route.query.id;
      this.shopId = this.$route.query.shopId;
      this.getGoodsInfo();
    }
    if (this.$route.query.type) {
      this.disabled = true;
    }
    this.getGoodsSorts();
  },
  mounted() {
  },
  methods: {
    goBack() {
      if (!this.isEditable) {
        const shopId = window.sessionStorage.getItem('shopId');
        this.$router.push({
          name: 'goodsList',
          query: {
            isEditable: this.disabled ? 1 : 0,
            id: shopId,
          }, 
        });
        // window.sessionStorage.removeItem('shopId');
      } else {
        // this.$router.push({ name: 'goodsList' });
        this.$router.go(-1);
      }
    },
    getGoodsSorts() {
      return $sgv20_getGoodsSort({ shopId: this.shopId }).then((res) => {
        this.goodsSort = res;
      });
    },
    getGoodsInfo() {
      $sgv20_getGoodsAttrAndSpec({ goodsId: this.goodsId, shopId: this.shopId }).then((res) => {
        const specArr = [];
        res.spec.forEach((item) => {
          const s = { goodsSpecName: '', goodsSpecPrice: '' };
          s.goodsSpecName = item.goodsSpecName;
          s.goodsSpecPrice = item.goodsSpecPrice;
          specArr.push(s);
        });
        this.specList = specArr;

        const arr = [];
        res.attr.forEach((item) => {
          const i = { goodsAttrName: '', goodsSpecNameList: [] };
          i.goodsAttrName = item.goodsAttrName;
          item.attrSpec.forEach((j) => {
            i.goodsSpecNameList.push({
              goodsSpecName: j.goodsSpecName,
              goodsSpecPrice: j.goodsSpecPrice,
            });
          });
          arr.push(i);
        });
        this.attrDtoList = arr;
      });
      const params = { goodsId: this.goodsId, shopId: this.shopId };
      $sgv20_getOneGoods(params).then((res) => {
        this.judgesuportBusiness = res.suportBusiness;
        Object.keys(this.form).forEach((key) => {
          this.form[key] = res[key];
        });
        const canChooseArr = [];
        this.models.forEach((item) => {
          if ((res.suportBusiness & item.value) > 0) {
            canChooseArr.push(res.suportBusiness & item.value);
          }
        });
        if (!canChooseArr.includes(1) && canChooseArr.includes(2)) {
          this.form.state = res.scanState;
        }
        this.checkedModel = canChooseArr;
        if (+res.suportBusiness === 2) {
          this.form.forceStock = res.forceScanStock;
          this.form.stockCount = res.scanStock;
        }
        console.log(this.checkedModel, 'checkedModel');
      });
    },
    getShopSupport() {
      const params = { shopId: this.$route.query.shopId };
      return $sgv20_getShopSupport(params).then((res) => {
        this.models.forEach((item) => {
          if ((res & item.value) > 0) {
            this.shopSupport.push(res & item.value);
          }
        });
        this.checkedModel = this.shopSupport;

        // this.$set(this.checkedModel, 0, this.shopSupport[0]);
      });
    },
    // 文件上传
    changeImg() {
      // const change = new ChooseFile();
      // change.chooseImage((file) => {
      //   this.getFile(file, type);
      // });
      const change = new ChooseFile();
      change.chooseImage(this.getFile.bind(this));
    },
    getFile(file) {
      const fileUploder = new FileUploader(file, { addLibrary: false, compress: false });
      fileUploder.upload().then((url) => {
        this.form.goodsPath = url;
      }).catch((error) => {
        this.$message.error(error.message || '上传失败，请重新上传');
      });
    },
    chooseOrInput() {
      this.isInput = !this.isInput;
    },
    selectState(num) {
      this.form.state = num;
    },
    // 选择商品份量单位
    selectGoodsUnit(value) {
      // 当选择商品单位为适用人数的时候，隐藏商品份量输入框，并且把值设为0
      if (value[0] === 3) {
        this.form.weightNum = 0;
      }
      this.form.goodsUnit = value[1];
    },
    async submit(subState) {
      if (this.loading) return Promise.reject();
      this.loading = true;
      try {
        await this.$validator(this.form, [
          'goodsPath/require/商品图片',
          'goodsName/require/商品名称',
          'describe/require/副标题',
        ]);
      } catch (e) {
        this.loading = false;
        return Promise.reject();
      }
      // await this.$validator(this.form.price, 'require', '外卖商品价格');
      // await this.$validator(this.form, [
      //   // 'scanOriginPrice/require/商品划线价',
      //   'scanPrice/require/商品现价',
      // ]);

      // try {
      //   if (this.checkedModel.includes(1)) {
      //     await this.$validator(this.form.price, 'require', '外卖商品价格');
      //   }
      // } catch (e) {
      //   this.loading = false;
      //   return Promise.reject();
      // }

      // try {
      //   if (this.checkedModel.includes(2)) {
      //     await this.$validator(this.form, [
      //       // 'scanOriginPrice/require/商品划线价',
      //       'scanPrice/require/商品现价',
      //     ]);
      //   }
      // } catch (e) {
      //   this.loading = false;
      //   return Promise.reject();
      // }

      if ((!this.form.goodsSortId && !this.isInput) || (!this.form.sortName && this.isInput)) {
        this.loading = false;
        this.$message.error('分类名称必填');
        return Promise.reject();
      }
      if (+this.form.forceStock === 2 && this.form.stockCount.length > 8) {
        this.loading = false;
        this.$message.error('请合理设置库存');
        return Promise.reject();
      }
      // || +this.form.scanOriginPrice.length > 6
      if (+this.form.price.length > 6 || +this.form.scanPrice.length > 6) {
        this.$message.error('价格设置不合理');
        return Promise.reject();
      }
      const arrWhole = this.specList.some((item) => !item.goodsSpecName || (!item.goodsSpecPrice && +item.goodsSpecPrice * 1 !== 0));
      if (arrWhole) {
        this.loading = false;
        this.$message.error('请将商品规格列表所有项填写完整，或删除不需要项！');
        return Promise.reject();
      }
      const attrWhole = this.attrDtoList.some((item) => {
        if (item.goodsSpecNameList.length > 0) {
          const liWhole = item.goodsSpecNameList.some((temp) => !temp);
          return !item.goodsAttrName || liWhole;
        } else {
          return !item.goodsAttrName || item.goodsSpecNameList.length === 0;
        }
      });
      if (attrWhole) {
        this.loading = false;
        this.$message.error('请将商品属性列表所有项填写完整，或删除不需要项！');
        return Promise.reject();
      }

      if (!this.form.goodsSortId && this.form.sortName) {
        const prams = {
          sortName: this.form.sortName,
          sortWeight: this.form.goodsWeight || '',
        };
        await $sgv20_saveOneGoodsSort(prams).then((res) => {
          this.form.goodsSortId = res;
        }).catch(() => {
          this.loading = false;
          return Promise.reject();
        });
      }
      const prams = {
        goods_id: this.goodsId || '', // 商品id
        goods_path: this.form.goodsPath, // 商品图片地址
        goods_weight: this.form.goodsWeight, // 商品权重值
        goods_name: this.form.goodsName, // 商品名称
        describe: this.form.describe, // 商品描述
        goods_sort_id: this.form.goodsSortId, // 商品分类id
        favorable: this.form.favorable, // 商品标签
        is_force_stock: this.form.forceStock, // 商品库存
        stock_count: this.form.stockCount, // 商品库存数量
        state: this.form.state, // 商品状态
        support_business: this.suportBusiness, // 商品类型
        // scanOriginPrice: this.form.scanOriginPrice, // 到店点餐划线价
        scan_price: this.form.scanPrice, // 到店点餐现价
        price: this.form.price, // 外卖价格
        // specList: this.specList, // 规格集合
        // attrDtoList: this.attrDtoList, // 属性集合
        pack: this.form.pack || 0, // 打包费
        init_num: this.form.value, // 起购份数
        weight_num: this.form.weightNum, // 商品重量
        goods_unit: this.form.goodsUnit, // 商品单位
        shop_id: this.shopId,
        single_able: this.form.singleAble, // 单点不送
        // scanOriginPrice: this.form.scanOriginPrice, // 商品划线价格
      };
      // 规格集合
      const spec = [];
      this.specList.forEach((val) => {
        spec.push({
          goods_spec_name: val.goodsSpecName,
          goods_spec_price: val.goodsSpecPrice,
        });
      });
      prams.spec = spec;
      // 属性集合
      const attr = [];
      this.attrDtoList.forEach((val, index) => {
        attr.push({
          goods_attr_name: val.goodsAttrName,
          spec: [],
        });
        val.goodsSpecNameList.forEach((v) => {
          attr[index].spec.push({
            goods_spec_name: v.goodsSpecName,
            goods_spec_price: v.goodsSpecPrice,
          });
        });
      });
      prams.attr = attr;
      // eslint-disable-next-line
      return $sp_goodsSave(prams).then((res) => {
        if (subState === 1) {
          this.goBack();
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        return Promise.reject();
      });
    },
    addSpec() {
      this.specList.push({ goodsSpecName: '', goodsSpecPrice: '' });
    },
    deleteSpec(index) {
      this.specList.splice(index, 1);
    },
    addAttr() {
      this.attrDtoList.push({
        goodsAttrName: '',
        goodsSpecNameList: [{
          goodsSpecName: '',
          goodsSpecPrice: '',
        }],
      });
    },
    deleteAttr(index) {
      this.attrDtoList.splice(index, 1);
    },
    clearChildAttr(data, index) {
      data.goodsSpecNameList.splice(index, 1);
    },
    addChildAttr(index) {
      this.attrDtoList[index].goodsSpecNameList.push(
        {
          goodsSpecName: '',
          goodsSpecPrice: '',
        },
      );
    },
    async backToList(subState) {
      await this.submit(subState);
      this.form = {
        goodsPath: '',
        sortName: '',
        goodsSortId: '',
        goodsName: '',
        describe: '',
        goodsWeight: 0,
        value: 1,
        favorable: 1,
        forceStock: 1,
        stockCount: 0,
        state: 1,
        // scanOriginPrice: '',
        scanPrice: '',
        price: '',
        pack: 0,
        weightNum: 0,
        goodsUnit: '克',
      };
      this.specList = [
        // { goodsSpecName: '', goodsSpecPrice: '' },
      ];
      this.attrDtoList = [
        // {
        //   goodsAttrName: '',
        //   goodsSpecNameList: [''],
        // },
      ];
      this.checkedModel = this.shopSupport;
    },
    deleteProduct() {
      this.$confirm('确认要删除该商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.deleteProductReally();
      }).catch(() => {
      });
    },
    deleteProductReally() {
      $sgv20_delGoods({ goodsId: this.goodsId }).then(() => {
        setTimeout(() => {
          this.$router.push({ name: 'GoodsList' });
        }, 2000);
      });
    },
    showSourceDialog() {
      this.dialogVisible = true;
      this.imgSource = 1;
      this.dialogImgList = [];
      this.searchName = this.form.goodsName || '';
      this.dialogChoseImg = '';
      this.page = 1;
      this.getImageSource();
    },
    chooseThisImg(data) {
      if (this.imgSource === 1) {
        this.dialogChoseImg = data.id;
      } else if (this.imgSource === 2) {
        this.dialogChoseImg = data;
      } 
    },
    // 素材库
    getImageSource() {
      $sg_getImageSource({
        page: this.page,
        type: 1,
        keywords: this.searchName,
        page_size: 24,
      }).then((resp) => {
        this.pageCount = +resp.count;
        this.dialogImgList = resp.data;
        this.page = +resp.current;
      });
    },
    findByName() {
      if (!this.searchName) {
        this.$message.warning('请输入商品名称');
        return;
      }
      const params = {
        name: this.searchName,
        pageCurrent: this.page,
      };
      $sg_findByName(params).then((res) => {
        this.dialogImgList = res;
      });
    },
    selectImgSource(val) {
      this.imgSource = val;
      this.page = 1;
      this.dialogChoseImg = '';
      if (val === 1) {
        this.getImageSource();
      } else {
        this.dialogImgList = [];
        this.findByName();
      }
    },
    dialogSearch() {
      this.dialogChoseImg = '';
      this.page = 1;
      if (this.imgSource === 1) {
        this.getImageSource();
      } else if (this.imgSource === 2) {
        this.findByName();
      }
    },
    dialogChoseConfirm() {
      if (!this.dialogChoseImg) {
        this.$message.error('请选择一张商品图片');
        return;
      }
      if (this.imgSource === 1) {
        const item = this.dialogImgList.find(((temp) => temp.id === this.dialogChoseImg));
        if (!item) {
          this.$message.error('请选择一张商品图片');
          return;
        }
        const url = item.url;
        if (!url) {
          this.$message.error('您选择的商品图片不可用，请重新选择');
          return;
        }
        this.form.goodsPath = url;
      } else if (this.imgSource === 2) {
        this.form.goodsPath = this.dialogChoseImg;
      }
      this.dialogVisible = false;
    },
    showPreviewImg(data) {
      this.isShowPreviewImg = true;
      if (this.imgSource === 1) {
        this.imgData = data;
      } else if (this.imgSource === 2) {
        this.imgData = {
          title: this.searchName,
          url: data,
        };
      }     
    },
    hidePreviewImg() {
      this.isShowPreviewImg = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/var.scss";
.goodsDetail-container {
  width: 100%;
  height: 100%;
  min-width: 1635px;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /deep/ .el-input {
    width: 204px;
  }
  .requiredIcon {
    color: $--theme-color;
    margin-left: 14px;
  }
  .header,
  .middle,
  .bottom {
    width: 100%;
    overflow: hidden;
    margin: 0 0 20px 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: $--white-color;
    .headerContent {
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 35px 20px 30px 35px;
      box-sizing: border-box;
      .headerContentLeft {
        width: 170px;
        overflow: hidden;
        margin: 0 80px 0 0;
        padding: 0;
        box-sizing: border-box;
        .avatar {
          width: 170px;
          height: 170px;
        }
        .updateImgTips {
          font-size: 12px;
          font-weight: 400;
          color: #aaaaaa;
          text-align: center;
          margin-top: 12px;
        }
        .changeImg {
          width: 170px;
          height: 32px;
          margin: 30px 0 0 0;
          padding: 0;
          box-sizing: border-box;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          color: #ffffff;
          text-align: center;
          background-color: $--theme-color;
          cursor: pointer;
        }
        .chooseFromWarehouse {
          font-size: 14px;
          font-weight: 400;
          color: $--theme-color;
          user-select: none;
        }
      }
      .headerContentMiddle {
        /deep/ .el-form-item--mini .el-form-item__content {
          line-height: 32px;
        }
        .el-form-item {
          margin-bottom: 25px;
        }
        .el-form-item:last-child {
          margin-bottom: 0;
        }
        .changeIndex {
          width: max-content;
          .changeIndexTitle {
            margin: 0 20px;
            font-size: 14px;
            font-weight: 400;
            color: $--theme-color;
            cursor: pointer;
            .detailAddC {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              cursor: pointer;
            }
          }
          .minPurchase {
            margin: 0 20px;
            font-size: 14px;
            font-weight: 400;
            color: #666;
          }
          .formGoodsWeightInput,
          /deep/ .formGoodsWeightInput .el-input {
            width: 105px;
          }
        }
        .packUnit {
          margin-left: 20px;
        }
      }
      .headerContentRight {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        .stateBtn {
          width: 110px;
          height: 32px;
          margin-right: 30px;
          box-sizing: border-box;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          color: #999999;
          text-align: center;
          border: 1px solid #aaaaaa;
          border-radius: 4px;
          cursor: pointer;
        }
        .selectedStateBtn {
          background-color: $--theme-color;
          color: #ffffff;
          border: none;
        }
      }
    }
  }
  .middle {
    .middleContent {
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 35px 35px 35px 67px;
      box-sizing: border-box;
      .middleContentLeft {
        .middleContentLeftTitle {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
        .middleContentLeftCon {
          margin-left: 44px;
          /deep/ .el-checkbox {
            display: block;
            margin-bottom: 30px;
            .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
              background-color: $--theme-color;
              border-color: $--theme-color;
            }
            .el-checkbox__input.is-disabled.is-checked
              .el-checkbox__inner::after {
              border-color: #fff;
            }
            .el-checkbox__input.is-disabled + span.el-checkbox__label {
              color: $--theme-color;
            }
          }
          .el-checkbox:last-child {
            margin-bottom: 0;
          }
        }
      }
      .middleContentMiddle {
        width: 1px;
        height: 41px;
        margin-left: 100px;
        background-color: #E4E4E4;
      }
      .middleContentRight {
        margin-left: 100px;
        .middleContentLeftTitle {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
        .middleContentRightCon {
          margin-left: 44px;
          .priceInput {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            .priceInputTitle {
              width: 70px;
            }
            .el-input {
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
  .bottom {
    margin: 0;
    .bottomContent {
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 35px 20px;
      box-sizing: border-box;
      .bottomContentTop {
        .bottomContentTopLeft {
          .specsList {
            .specs {
              padding: 10px 10px 0 0;
              position: relative;
              margin-bottom: 10px;
              .specsInput {
                background-color: #f5f5f5;
                padding: 20px 0 20px 20px;
                .becauseAddIcon {
                  position: relative;
                  margin-right: 20px;
                  .el-input {
                    margin-right: 5px;
                  }
                  > .addMoneyIcon {
                    position: absolute;
                    top: 0;
                    left: 12px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 32px;
                    color: #999999;
                  }
                  .addMoneyUnit {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    margin-left: 15px;
                  }
                  /deep/ .el-input__inner {
                    padding-left: 25px;
                  }
                }
                .el-input {
                  margin-right: 20px;
                }
              }
              .closeAttr {
                width: 18px;
                height: 18px;
                position: absolute;
                top: 2px;
                right: 2px;
                cursor: pointer;
              }
            }
          }
        }
        .bottomContentTopMiddle {
          width: 1px;
          height: 135px;
          margin: 100px 85px 0 85px;
          background-color: #e6e6e6;
        }
        .bottomContentTopRight {
          .attrList {
            max-width: 708px;
            .attr {
              padding: 10px 10px 0 0;
              margin-bottom: 10px;
              position: relative;
              .attrDiv {
                background-color: #f5f5f5;
                padding: 20px 0 0 20px;
                .attrName {
                  margin-bottom: 20px;
                  .el-input {
                    margin: 0 20px 0 0;
                  }
                  .childAddBtn {
                    width: 70px;
                    height: 32px;
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 32px;
                    color: $--theme-color;
                    text-align: center;
                    border-radius: 4px;
                    border: 1px solid $--theme-color;
                    cursor: pointer;
                  }
                }
                .attrChild {
                  .el-input {
                    margin: 0 20px 20px 0;
                  }
                }
              }

              .closeAttr {
                width: 18px;
                height: 18px;
                position: absolute;
                top: 2px;
                right: 2px;
                cursor: pointer;
              }
            }
          }
        }
        .specsAttrTitle {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          margin: 0 0 20px 15px;
        }
        .specsAttrExplainAndAddBtn {
          margin-bottom: 10px;
          .specsAttrExplain {
            font-size: 14px;
            font-weight: 400;
            color: $--theme-color;
            margin: 0 40px 0 15px;
          }
          .specsAttrAddBtn {
            width: 92px;
            height: 32px;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 400;
            line-height: 32px;
            color: #ffffff;
            text-align: center;
            background-color: $--theme-color;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
      .bottomContentBottom {
        margin-top: 40px;
        .closeEdit,
        .submitEdit,
        .deleteEdit {
          width: 128px;
          height: 32px;
          margin: 0 20px;
          padding: 0;
          box-sizing: border-box;
          font-size: 12px;
          font-weight: 400;
          line-height: 32px;
          color: #ffffff;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;
        }
        .closeEdit {
          background-color: #4d4d4d;
        }
        .submitEdit {
          background-color: $--theme-color;
        }
        .deleteEdit {
          border: 1px solid #ececec;
          color: #999999;
        }
      }
    }
  }
  .choiceModule {
    width: 100%;
    height: 65px;
    margin: 0;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid #E4E4E4;
    .title {
      width: max-content;
      overflow: hidden;
      margin: 0 10px 0 0;
      padding: 0;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: bold;
      color: #4d4d4d;
    }
    .title::before {
      display: inline-block;
      content: "";
      width: 3px;
      height: 19px;
      margin: 0 12px 0 0;
      padding: 0;
      background-color: $--theme-color;
    }
    .required {
      font-size: 14px;
      font-weight: 400;
      color: #ef3f46;
      margin-right: 10px;
    }
    .attrExplain {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
  /deep/ .el-dialog {
    border-radius: 6px;
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__header {
      padding: 0;
    }
    .dialogTitle {
      width: 100%;
      height: 46px;
      margin: 0;
      padding: 10px 20px 10px 35px;
      background-color: #ececec;
      box-sizing: border-box;
      border-radius: 6px 6px 0 0;
      align-items: center;
      .dialogTitleName {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      .dialogClose {
        width: 10px;
        height: 10px;
        cursor: pointer;
      }
    }
    .imgSource{
      width: 100%;
      padding: 0px 40px;
      padding-top: 30px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f0f0f0;
      box-sizing: border-box;
      span{
        margin-right: 40px;
        user-select: none;
        cursor: pointer;
      }
      .active{
       color: $--theme-color; 
      }
    }
    .dialogSearchDiv {
      width: 100%;
      height: 76px;
      margin: 0;
      padding: 22px 40px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .searchIcon {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
    .dialogChoiceModule {
      width: 100%;
      margin: 0;
      padding: 20px 40px 10px 40px;
      box-sizing: border-box;
      position: relative;
      .title {
        width: max-content;
        overflow: hidden;
        margin: 0 10px 0 0;
        padding: 0;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: bold;
        color: #4d4d4d;
      }
      .title::before {
        display: inline-block;
        content: "";
        width: 3px;
        height: 20px;
        margin: 0 7px 0 0;
        padding: 0;
        background-color: $--theme-color;
      }
    }
    .dialogImgContentDiv {
      width: 100%;
      height: auto;
      max-height: 450px;
      overflow: auto;
      margin: 20px 0 0 0;
      padding: 0 25px 0 40px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .dialogImgContent {
        width: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        .dialogImgLi {
          width: 96px;
          overflow: hidden;
          padding: 0;
          margin: 0 15px 22px 0;
          box-sizing: border-box;
          position: relative;
          .isChoseDiv {
            width: 96px;
            height: 96px;
            padding: 8px;
            margin: 0;
            box-sizing: border-box;
            border: 1px solid #fff;
            .dialogImg {
              width: 80px;
              height: 80px;
              position: relative;
            }
            .dialogImg::after{
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: url('/img/goods/example.png');
              background-size:80px 80px;
              background-repeat:no-repeat;
            }
          }
          .dialogChoseImg {
            border: 1px solid $--theme-color;
            background-color: rgba(239, 63, 70, 0.07);
          }
          .dialogProductName {
            overflow: hidden;
            padding: 0 8px;
            margin: 10px 0 0 0;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .previewIcon {
            display: none;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 12px;
            right: 10px;
            cursor: pointer;
          }
          &:hover .previewIcon {
            display: block;
          }
        }
      }
    }
    .dialogPage {
      width: 100%;
      height: 80px;
      margin: 0;
      padding: 0 40px 0 50px;
      box-sizing: border-box;
      .el-input {
        width: 50px !important;
      }
      .dialogBtn {
        width: 110px;
        height: 32px;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
        line-height: 32px;
        text-align: center;
      }
      .cancel {
        border: 1px solid #ececec;
        color: #999999;
        margin-right: 20px;
      }
      .confirm {
        background-color: $--theme-color;
        color: #fff;
      }
    }
    .dialogBlank {
      width: 100%;
      height: 24px;
      background-color: #ececec;
      border-radius: 0 0 6px 6px;
    }
  }
  .mt20{
    margin-top: 20px;
  }
}
</style>
