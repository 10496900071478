import api from './api';

// 待审核商户页面
export const $sg_notReviewedShopPage = (params) => api.post('/j/agent/t-shop-info/notReviewedShopPage', params);

// 请求归属分公司列表
export const $sp_reviewedShopPage = (params) => api.postNoMessage('/j/platform/t-spread-info/list', params);

// // 请求商户列表
// export const $sp_shopInfoList = (params) => api.postNoMessage('/j/platform/t-shop-info/list', params);

// 查询商户列表
export const $sp_shopInfoList = (params) => api.get('/sssk/api/platform/biz/merchant/list', params);
// 业务上下架
export const $sp_updateShopSupportBusiness = (params) => api.postNoMessage('/sssk/api/platform/biz/merchant/updateShopSupportBusiness', params);
// 获取商户详情
export const $sp_shopInfo = (params) => api.get(`/sssk/api/platform/biz/merchant/shopInfo/${params}`);
// 编辑商户详情
export const $sp_groupupdate = (params) => api.postNoMessage('/sssk/api/platform/biz/merchant/updateShop', params);
// 编辑商户详情
export const $sp_updateShop = (params) => api.post('/sssk/api/platform/biz/merchant/updateShop', params);

// 商家详情
export const $sp_shopInfoDetail = (params) => api.postNoMessage('/j/platform/t-shop-info/detail', params);

// 商家详情-订单数量统计
export const $sg_orderStatics = (params) => api.get(`/j/platform/t-shop-info/order-statics/${params}`);

// 商家详情-商户商品数量统计
export const $sg_goodsStatics = (params) => api.get(`/j/platform/t-shop-info/goods-statics/${params}`);

// 商家详情-商户台位数量统计
export const $sg_tableStatics = (params) => api.get(`/j/platform/t-shop-info/table-statics/${params}`);

// 修改补贴配送费
export const $sp_updateSubsidyDeliveryMoney = (params) => api.post('/j/platform/t-shop-config/update', params);

// 添加商户页面
export const $sg_join = (params) => api.post('/j/agent/t-shop-join/join', params);

// 添加商户页面
export const $sg_updateByJoinId = (params) => api.post('/j/agent/t-shop-join/updateByJoinId', params);

// 查询商户信息
export const $sg_queryByContactTel = (params) => api.get('/j/agent/t-shop-join/queryByContactTel', params);
// 保存和修改引流信息 /j/platform/Billboard/saveBillboard
export const $sg_saveBillboard = (params) => api.post('/j/platform/billboard/saveBillboard', params);
// 总平台获取引流信息
export const $sg_getBillboard = (params) => api.get('/j/platform/billboard/getBillboard', params);

// 绑定顺丰门店
export const $sp_bindSfShop = (shopId, params) => api.post(`/j/platform/t-shop-info/bindSfShop/${shopId}`, params);
// 返利商户申请列表
export const $sg_shopJoinList = (params) => api.get('/j/rebate/shop/joinList', params);
// 通过
export const $sp_shopAgree = (params) => api.post('/j/rebate/shop/agree', params);
// 驳回商户申请
export const $sp_shopRefuse = (params) => api.post('/j/rebate/shop/refuse', params);
// 待审核商户列表
export const $sg_merchantjoinList = (params) => api.get('/sssk/api/platform/biz/merchant/joinList', params);
// 待审核商户列表详情
export const $sp_merchantshopInfo = (params) => api.get(`/sssk/api/platform/biz/merchant/auditInfo/${params}`);
// 提交审核
export const $sp_merchantaudit = (params) => api.post('/sssk/api/platform/biz/merchant/audit', params);
// 商户主营类目
export const $sp_getShopCate = (params) => api.get('/sssk/api/agent/biz/SysDictItem/getShopCate', params);
