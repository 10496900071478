<template>
  <el-dialog class="dialog-page" :visible="showDialog" :width="dialogs&&dialogs.content2?'501px':dialogWidth"  @close="closeDialog"
    :show-close="false" :append-to-body="append">
    <div class="dialog-main flexbox flex-tb flex-align-center">
      <div class="center">
        <img :src="dialogs.icon" class="icon" v-if="dialogs&&dialogs.icon" />
      </div>
      <div class="title" v-if="dialogs&&dialogs.title">{{dialogs.title}}</div>
      <div class="title2 flexbox flex-align-center" v-if="dialogs&&dialogs.title2">
        <img src="/img/dialog/diagonal.png" class="diagonal" />
        <span style="margin:0 27px;">{{dialogs.title2}}</span>
        <img src="/img/dialog/diagonal.png" class="diagonal" />
      </div>
      <div class="content" v-if="dialogs&&dialogs.content">{{dialogs.content}}</div>
      <div class="content2" v-if="dialogs&&dialogs.content2">
        <div v-for="(item, index) in dialogs.content2" :key="index">
          <div class="title">{{item.title}}</div>
          <div class="list" v-for="(ite, inde) in item.list" :key="inde">{{ite}}</div>
        </div>
      </div>
      <slot></slot>
      <div class="done-btn pointer" @click="doneBtn" v-if="dialogs&&dialogs.done">
        {{dialogs.done}}</div>
      <div v-if="!dialogs.done&&dialogs.rightBtn" class="double-btn">
        <div class="left-btn" @click="leftBtn">{{dialogs.leftBtn}}</div>
        <div class="right-btn" @click="rightBtn">{{dialogs.rightBtn}}</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    dialogWidth: {
      type: String,
      default: '400px',
    },
    append: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      dialogs: {},
    };
  },
  methods: {
    leftBtn() {
      this.$emit('leftBtn');
    },
    rightBtn() {
      this.$emit('rightBtn');
    },
    doneBtn() {
      this.$emit('doneBtn');
      this.closeDialog();
    },
    closeDialog() {
      this.showDialog = false;
    },

  },
};
</script>
<style lang="scss">
.dialog-page {
  .el-dialog__header {
    padding: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
<style scoped lang="scss">
@import "@/style/var.scss";
.dialog-page {
  .icon {
    width: 82px;
    height: 82px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    color: #333333;
    text-align: center;
    margin-top: 23px;
  }
  .title2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
    margin-top: 17px;
    text-align: center;
    .diagonal {
      width: 24.37px;
      height: 14.02px;
    }
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
    text-align: center;
    margin-top: 12px;
    padding: 0 10px 10px 10px;
  }
  .content2 {
    padding: 0 14px;
    max-height: 450px;
    overflow-y:auto;
    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      margin:12px 0;
      text-align: left;
    }
    .list{
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #666666;
    }
  }
  .done-btn {
    width: 260px;
    height: 36px;
    line-height: 36px;
    background: $--theme-color;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin: 31px auto 0;
  }
  .gradients-btn.van-button {
    font-size: 32px !important;
    height: 80px !important;
    line-height: 80px !important;
  }
  .double-btn {
    margin: 28px 0px 0px 0px;
    // padding: 0 86px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    .left-btn {
      width: 210px;
      height: 80px;
      background: #ededed;
      opacity: 1;
      border-radius: 40px;
      font-size: 32px;
      color: #444444;
      text-align: center;
      line-height: 80px;
    }
    .right-btn {
      color: #ffffff;
      background: $--theme-color;
      width: 210px;
      height: 80px;
      opacity: 1;
      border-radius: 40px;
      font-size: 32px;

      text-align: center;
      line-height: 80px;
    }
  }
}
</style>
