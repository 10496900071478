<template>
  <div v-if="isShowPreviewImg" class="preview-img-container" @click="hidePreviewImg">
    <div class="preview-img-content">
      <div class="preview-img-title">
        <div class="preview-img-name">{{imgData.title}}</div>
        <img class="close" src="/img/goods/close.png" alt="">
      </div>
      <div class="img-div beautiful-scorll-bar">
        <img class="img-content" :src="imgData.url" alt="">
      </div>
      <div class="blank"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewImg',
  props: {
    imgData: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowPreviewImg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    hidePreviewImg() {
      this.$emit('hidePreviewImg');
    },
  },
};
</script>

<style scoped lang="scss">
  .preview-img-container{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 9999!important;
    .preview-img-content{
      width: 1100px;
      height: max-content;
      max-height:835px;
      margin: auto;
      padding: 0;
      box-sizing: border-box;
      background-color: #ECECEC;
      border-radius: 6px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .preview-img-title{
        width: 100%;
        height: 65px;
        margin: 0;
        padding: 0 20px;
        box-sizing: border-box;
        background-color: #ECECEC;
        position: relative;
        border-radius: 6px 6px 0 0;
        .preview-img-name{
          font-size: 20px;
          font-weight: 500;
          line-height: 65px;
          color: #333333;
        }
        .close{
          width: 12px;
          height: 12px;
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }
      .img-div{
        width: 100%;
        max-height: 735px;
        overflow: auto;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background-color: #fff;
        text-align: center;
        .img-content{
          max-width: 100%;
          max-height: 735px;
        }
      }
      .blank{
        width: 100%;
        height: 35px;
      }
    }
  }
</style>
