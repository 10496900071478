<template>
  <div class="imgBox">
    <div class="imgList mt30" v-for="(item,index) in imgList" :key="item.sort">
      <el-upload action="#" :disabled="disabled" :http-request="(file)=>img_ad_upload(file,index)" :show-file-list="false">
        <img class="goodsPath" :src="item.url" alt="" v-if="item.url">
        <div class="addGoodsPath flexbox flex-align-center flex-justify-center" v-else>
          <i class="el-icon-plus"></i>
        </div>
        <p v-if="item.title" class="mt20">{{ item.title }}</p>
      </el-upload>
    </div>
  </div>
</template>

<script>
import FileUploader from '@base/utils/file/FileUploader';

export default {

  props: {
    imgList: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    async img_ad_upload(file, index) {
      const arr = file.file.name.split('.');
      const fileUploder = new FileUploader(file.file, { addLibrary: false, compress: true, ext: arr[arr.length - 1] });
      console.log(arr);
      fileUploder.upload().then((url) => {
        this.imgList[index].url = url;
        this.$emit('dataFormImgBox', this.imgList);
      }).catch((error) => {
        this.$message.error(error.message);
      });
    },
  },

};
</script>

<style lang="scss" scoped>
.imgBox {
  .mt20{
    margin-top: 20;
  }
  .imgList {
    width: 25%;
    display: flex;
    justify-content: space-around;
    .goodsPath {
      width: 170px;
      height: 170px;
      border-radius: 8px;
      margin-top: 10px;
    }
    .addGoodsPath {
      width: 170px;
      height: 170px;
      border-radius: 8px;
      margin-top: 10px;
      border: 1px dashed #999;
      background-color: #f5f5f5;
      font-size: 50px;
      color: #999;
    }
  }
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
}
</style>
